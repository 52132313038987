import loading from '../../assets/gif/loading.gif';

function Loading() {
  return (
    <div className="loading-container">
      <div className="loading-text-wrapper">
        <div>
          <img src={loading} alt="loading" className="loading-gif" />
        </div>
      </div>
    </div>
  );
}
export default Loading;
