import '../../styles/recent.scss';
import { useContext, useState } from 'react';
import { SvgSelector } from '../../helper/svg-selector';
import AnimationSlide from '../Elements/AnimationSlide';
import ModalImport from '../Elements/ModalImport';
import MultiSelection from './MultiSelection';
import { DataContext } from '../../context/DataContext';
import ProOptionCard from '../ProAccount/ProOptionCard';
import Button from '../Elements/Button';

function SelectBoard({
  shownImportModal,
  showImportModal,
  clickHandlerProject,
  selectedTab,
  checkMultiSelect,
  showModalMoveIn,
  deleteMultiData,
  deleteForEverMultiData,
  setUploadingFile,
  navigateAddData,
  currentProject,
  allData,
  setAllData,
  setToastInfo,
}) {
  const { isProUser, totalObjects } = useContext(DataContext);
  const [showProCard, setShowProCard] = useState('');

  const boardLimitText = `${totalObjects.totalBoards} out of 15 boards`;
  const projectLimitText = `${totalObjects.totalProjects} out of 5 projects`;
  const proProjectLimitText = `${totalObjects.totalProjects} out of 50 projects`;

  return (
    <div>
      {checkMultiSelect ? (
        <div className="selected-board-position-container">
          <MultiSelection
            showModalMoveIn={showModalMoveIn}
            selectedTab={selectedTab}
            deleteMultiData={deleteMultiData}
            hardDeleteForEverData={deleteForEverMultiData}
          />
        </div>
      ) : selectedTab !== 4 ? (
        <div className="selected-board-position-container">
          <div className="recent-select-board-container">
            <div className="font-18-bold">Create new object</div>
            <div className="recent-select-board">
              <div
                className="box-shadow cursor-pointer recent-create-board-button"
                onClick={() => clickHandlerProject('addBoard', setShowProCard)}
              >
                {SvgSelector('addBoard')}
                <div>
                  <div className="recent-create-board-text">
                    <span className="font-14-med recent-create-board-title">
                      Create board
                      <AnimationSlide
                        openMenu={setShowProCard}
                        show={showProCard === 'boardPro'}
                        state="topToDown"
                      >
                        <div className="project-card-pro">
                          <ProOptionCard
                            closeMenu={setShowProCard}
                            proText="Only 15 board.Use PRO without restrictions!"
                          />
                        </div>
                      </AnimationSlide>
                    </span>
                    {SvgSelector('plus', 'add-project-icon')}
                  </div>
                  <span className="font-10-reg limit-reminder-text">
                    {`${isProUser ? 'Unlimited boards' : boardLimitText}`}
                  </span>
                </div>
              </div>
              <div
                className="box-shadow cursor-pointer recent-create-project-button"
                onClick={() =>
                  clickHandlerProject('addProject', setShowProCard)
                }
              >
                {SvgSelector('addProject')}
                <div>
                  <div className="recent-create-board-text">
                    <span className="font-14-med recent-create-board-title">
                      Create project
                      <AnimationSlide
                        openMenu={setShowProCard}
                        show={showProCard === 'projectPro'}
                        state="topToDown"
                      >
                        <div className="project-card-pro">
                          <ProOptionCard
                            closeMenu={setShowProCard}
                            proText="Only 5 project.Use PRO without restrictions!"
                          />
                        </div>
                      </AnimationSlide>
                    </span>
                    {SvgSelector('plus', 'add-project-icon')}
                  </div>
                  <span className="font-10-reg limit-reminder-text">
                    {`${isProUser ? proProjectLimitText : projectLimitText}`}
                  </span>
                </div>
              </div>
              <div className="recent-select-board-wrapper">
                {SvgSelector('selectFile')}
                <div className="recent-select-board-button-container">
                  <span className="font-16-bold" style={{ minWidth: '192px' }}>
                    Upload or embed your file
                  </span>
                  {isProUser ? (
                    <Button
                      labelClass="font-14-bold"
                      label="Select file"
                      textColor="white"
                      color="blue_strong"
                      onClick={() => clickHandlerProject('importModalAi')}
                      width={117}
                      height={36}
                      image={SvgSelector(
                        'uploadBoard',
                        'recent-select-board-icon',
                      )}
                    />
                  ) : (
                    <div>
                      <div
                        className="recent-select-board-button font-14-bold cursor-pointer"
                        onClick={() =>
                          clickHandlerProject('importModalAi', setShowProCard)
                        }
                      >
                        Select file
                        <span>PRO</span>
                      </div>
                      <AnimationSlide
                        openMenu={setShowProCard}
                        show={showProCard === 'uploadPro'}
                        state="topToDown"
                      >
                        <div className="upload-card-pro">
                          <ProOptionCard
                            closeMenu={setShowProCard}
                            proText="Want to create board form file? Sign up for a tariff level right now!"
                          />
                        </div>
                      </AnimationSlide>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <AnimationSlide
        show={shownImportModal}
        openMenu={showImportModal}
        state="topToDown"
      >
        <ModalImport
          openModal={showImportModal}
          setUploadingFile={setUploadingFile}
          navigateAddData={navigateAddData}
          currentProject={currentProject}
          allData={allData}
          setAllData={setAllData}
          shownImportModal={shownImportModal}
          setToastInfo={setToastInfo}
        />
      </AnimationSlide>
    </div>
  );
}

export default SelectBoard;
