import '../../styles/template.scss';
import { SvgSelector } from '../../helper/svg-selector';

function CardTemplate({ clickHandler, templateData }) {
  const name = templateData.title[0].text;
  const imageUrl = name
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/'/g, '')
    .replace(/&/g, 'and');
  return (
    <div
      className="template-card-container cursor-pointer"
      onClick={() => templateData?.id && clickHandler(templateData.id)}
    >
      <div className="template-card-img">
        <img
          // eslint-disable-next-line import/no-dynamic-require, global-require
          src={require(`../../assets/jpg/${imageUrl}.jpg`)}
          alt={templateData?.title[0].text}
        />
      </div>
      <div className="template-card-title-container">
        <div className="template-card-title-wrapper">
          <div
            className="font-14-med template-card-name"
            title={templateData?.title[0].text}
          >
            {templateData?.title[0].text}
          </div>
          {/* <div className="font-12-reg template-card-text">
            Description of template. Limited by 255 characters. Shown in card
            with 2 lines.
          </div> */}
        </div>
        <div className="template-card-feature">
          <div className="template-card-feature-wrapper">
            <div className="template-card-feature font-12-med">0 used</div>
          </div>
          <div className="template-card-feature-wrapper">
            <div className="template-card-feature font-12-med mx-5">0</div>
            {SvgSelector('like', 'template-card-feature')}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardTemplate;
