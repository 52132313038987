import { SvgSelector } from '../../helper/svg-selector';
import '../../styles/proAccount.scss';
import giftBox from '../../assets/png/gift-box.png';
import dataServices from '../../services/dataServices';

function EnterprisePlan({ data, setIsLoading }) {
  const user = JSON.parse(localStorage.getItem('user'));

  const selectPlan = (type) => {
    if (type !== user.active_plan) {
      setIsLoading(true);
      const params = {
        plan: data.id,
      };
      dataServices.payment(params).then((res) => {
        setIsLoading(false);
        window.location.href = res.data.url;
      });
    }
  };

  return (
    <div className="tariff-border tariff-container-border">
      <div className="tariff-item-container">
        <div className="tariff-pro-subject">
          <span className="font-24-reg">Enterprise</span>
          <div className="tariff-pro-tag">
            <span className="font-16-med">Individually</span>
          </div>
        </div>
        <div className="tariff-pro-title">
          <div className="tariff-pro-title">
            <div className="tariff-item-title-price-margin">
              <span
                className="font-h1 text-price-enterprise"
                style={{ marginRight: '8px' }}
              >
                $2.79
              </span>
              <span className="font-14-med tariff-item-title-before">
                / month
              </span>
            </div>
          </div>
        </div>
        <div
          className="cursor-pointer font-16-bold tariff-choose-plan-contactUs"
          onClick={() => selectPlan('ENTERPRISE')}
        >
          {user?.active_plan === 'ENTERPRISE' ? 'Activated now' : 'Select plan'}
          {SvgSelector('logo', 'logo-contact-us-icon')}
        </div>
        <span className="tariff-feature-text font-16-reg">
          Become our investor and help make Layer better for the world — buy us
          a coffee!
        </span>
        <div>{SvgSelector('proMan')}</div>
      </div>
      <div className="tariff-sponsor-container">
        <img
          src={giftBox}
          alt="giftBox"
          style={{ width: '34px', height: '34px' }}
        />
        <span className="font-14-med">
          *As a special bonus, you’ll receive “Sponsor” status within our
          community!
        </span>
      </div>
    </div>
  );
}

export default EnterprisePlan;
