import { useContext, useState } from 'react';
import { SvgSelector } from '../../helper/svg-selector';
import '../../styles/modalSharing.scss';
import AnimationSlide from './AnimationSlide';
import Button from './Button';
import { DataContext } from '../../context/DataContext';
import { accessList, accessName } from '../../constants/arrays';

function ModalSharingCopyLink({ shareLayer, node, copyLink, type }) {
  const { treeData } = useContext(DataContext);

  const accessNode = treeData[node].public_privilege || 'NO_ACCESS';
  const [openMenu, setOpenMenu] = useState(false);
  const [shareAccess, setShareAccess] = useState({
    name: accessName[accessNode],
    value: accessNode,
  });

  const { boardId } = useContext(DataContext);

  const shareNode = (access, item) => {
    shareLayer(access, type);
    setShareAccess(item);
  };

  return (
    <div className="copy-link-container">
      <div className="font-small-reg copy-link-title">Share externally</div>
      <div className="copy-link-text-container">
        <div>{SvgSelector('link')}</div>
        <input
          type="text"
          className="copy-link-input"
          value={`${`${document.location.origin}/${node || boardId}`}`}
        />
        <div
          className="cursor-pointer copy-link-text-access font-14-med"
          onClick={() => setOpenMenu(true)}
        >
          <span className="copy-link-access-text">{shareAccess.name}</span>
          <div className="copy-link-icon-link align-center">
            {SvgSelector('chevronDown')}
          </div>
          <AnimationSlide
            show={openMenu}
            openMenu={setOpenMenu}
            state="topToDown"
          >
            <div className="copy-link-change-access cursor-pointer">
              {accessList.map((i) => (
                <div
                  className="copy-link-access-item"
                  onClick={() =>
                    shareNode(i.value, { name: i.text, value: i.value })
                  }
                >
                  <div className="copy-link-access-icon-container">
                    <div>
                      {SvgSelector(`${i.icon}`, 'copy-link-access-icon')}
                    </div>
                    <span>{i.text}</span>
                  </div>
                  {i.value === shareAccess.value && (
                    <div>{SvgSelector('checkMark')}</div>
                  )}
                </div>
              ))}
            </div>
          </AnimationSlide>
        </div>
        <Button
          color="blue_strong"
          height={36}
          imageL={SvgSelector('link', 'copy-link-icon mr-5')}
          label="Copy link"
          textColor="white"
          width={230}
          style={{ display: 'flex', gap: '2px', fontWeight: 600, fontSize: 14 }}
          onClick={() => copyLink(node)}
        />
      </div>
    </div>
  );
}

export default ModalSharingCopyLink;
