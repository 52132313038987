import { SvgSelector } from '../../helper/svg-selector';
import '../../styles/proAccount.scss';
import dataServices from '../../services/dataServices';
import { specialProFeature } from '../../constants/arrays';
import productHuntLogo from '../../assets/png/product-hunt.png';

function ProTariff({ data, setIsLoading }) {
  const user = JSON.parse(localStorage.getItem('user'));

  const calculatePrice = data.price_in_cents / 100;
  const price = calculatePrice.toFixed(2);
  const priceBeforeDiscount = data.price_in_cents_before_discount / 100;
  const calculateTime = Math.floor(data.duration_in_days / 30);
  const calculatePerMonth = calculateTime < 2 ? '' : ` ${calculateTime}`;

  const selectPlan = (type) => {
    if (type !== user.active_plan && user.active_plan !== 'ENTERPRISE') {
      setIsLoading(true);
      const params = {
        plan: data.id,
      };
      dataServices.payment(params).then(() => {
        setIsLoading(false);
        window.location.href = user?.root.id;
      });
    }
  };

  return (
    <div className="gradient-border">
      <div className="tariff-item-container">
        <div className="tariff-pro-subject">
          <span className="font-24-reg">PRO</span>
          <div className="tariff-pro-tag tooltip cursor-pointer">
            {SvgSelector('starsFull')}
            <span className="font-16-med AI-tag">AI Assist</span>
            <span className="tooltiptext font-10-med tariff-pro-tag-tooltip">
              Helps scale up your project
              <span className="tariff-pro-tag-tooltip-color">PRO</span>
            </span>
          </div>
        </div>
        <div className="tariff-pro-title">
          <div className="tariff-item-title-price-margin">
            <span className="font-18-bold tariff-item-title-price">
              super sale
            </span>
            <br />
            <span className="font-h1 tariff-item-title-price">
              {`$ ${price}`}
            </span>
          </div>
          <del className="font-28-reg tariff-item-title-before">{`$ ${priceBeforeDiscount}`}</del>
          <span className="font-14-med tariff-item-title-before">
            /{`${calculatePerMonth} month`}
          </span>
        </div>
        <div
          className="cursor-pointer tariff-choose-plan"
          onClick={() => selectPlan('PRO')}
        >
          <span className="font-16-bold" style={{ color: 'var(--white)' }}>
            {user?.active_plan === 'PRO' ? 'Activated now' : 'Select plan'}
          </span>
        </div>
        <div className="tariff-tag-product">
          <img src={productHuntLogo} alt="productHuntLogo" />
        </div>
        <span className="tariff-feature-text-pro font-16-reg">
          After winning on Product Hunt <br /> and receiving invaluable
          feedback, <br />
          we’ve decided to <br />
          <span className="font-16-bold">
            open Layer to the world for free!
          </span>
          <br />
          All PRO features are now available to
          <br />
          <span className="font-16-bold">everyone!</span>
        </span>
        <span className="font-16-bold">Limits</span>
        <div className="tariff-feature-container">
          {specialProFeature.map((i, index) => (
            <div
              className="tariff-feature-item"
              key={i.id}
              style={{ gap: index > 1 ? '20px' : '16px' }}
            >
              <div>
                {SvgSelector(
                  `${index > 2 ? 'checkMark' : 'infinity'}`,
                  `${index > 2 ? 'tariff-limit-icon' : 'tariff-limit-icon-infinity'}`,
                )}
              </div>
              <span className="font-16-reg">{i.text}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ProTariff;
