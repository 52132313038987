import APIKit from './http';

const dataServices = {
  getLayerById: async (id, params) => {
    const res = await APIKit.get(`api/layers/${id}/`, { params });
    return res;
  },
  getTasks: async (params) => {
    const res = await APIKit.get(
      `api/layers/tasks/?board_id__in=${params.board_id}&status__in=${params.status}&color__in=${params.tag}&assignments__in=${params.assignments.userNames ? params.assignments.userNames : ''}&due_time__gt=${params.due_date?.due_timeGt ? params.due_date.due_timeGt : ''}&due_time__lt=${params.due_date?.due_timeIt ? params.due_date.due_timeIt : ''}`,
    );
    return res;
  },
  getLayerDescendants: async (id, params) => {
    const res = await APIKit.get(`api/layers/${id}/descendants/`, {
      params,
    });
    return res;
  },
  getLayerChildren: async (id, params) => {
    const res = await APIKit.get(`api/layers/${id}/children/`, {
      params,
    });
    return res;
  },
  getRecentLayerChildren: async () => {
    const res = await APIKit.get(`api/layers/recents/?sub_count=true`);
    return res;
  },
  getSharedLayers: async () => {
    const res = await APIKit.get('api/layers/shared/');
    return res;
  },
  getSharedWithMeLayers: async () => {
    const res = await APIKit.get('api/layers/shared/with-me/');
    return res;
  },
  getSharedByMe: async () => {
    const res = await APIKit.get('api/layers/shared/by-me/');
    return res;
  },
  getDeletedDescendants: async (id) => {
    const res = await APIKit.get(`/api/layers/${id}/deleted_descendants/`);
    return res;
  },
  addLayers: async (data) => {
    const res = await APIKit.post('api/layers/', data, {
      withCredentials: true,
    });
    return res;
  },
  addBlock: async (data) => {
    const res = await APIKit.post('api/layers/upsert/', data, {
      withCredentials: true,
    });
    return res;
  },
  bulkCreate: async (data) => {
    const res = await APIKit.post('api/layers/bulk-create/', data, {
      withCredentials: true,
    });
    return res;
  },
  updateLayers: async (id, params) => {
    const res = await APIKit.patch(`api/layers/${id}/`, params);
    return res;
  },
  relocateLayers: async (data) => {
    const res = await APIKit.post('api/layers/multi-relocate/', data, {
      withCredentials: true,
    });
    return res;
  },
  relocate: async (id, params) => {
    const res = await APIKit.post(`api/layers/${id}/relocate/`, params);
    return res;
  },
  deleteLayer: async (id) => {
    const res = await APIKit.delete(`api/layers/${id}/`);
    return res;
  },
  unDeleteLayer: async (id) => {
    const res = await APIKit.post(`/api/layers/${id}/undelete/`, {
      withCredentials: true,
    });
    return res;
  },
  hardDeleteLayer: async (id) => {
    const res = await APIKit.post(`/api/layers/${id}/hard-delete/`, {
      withCredentials: true,
    });
    return res;
  },
  updateMultiLayers: async (params) => {
    const res = await APIKit.patch('/api/layers/multi-update/', params);
    return res;
  },
  hardDeleteMultiLayers: async (data) => {
    const res = await APIKit.post('/api/layers/multi-hard-delete/', data, {
      withCredentials: true,
    });
    return res;
  },
  deleteMultiLayers: async (data) => {
    const res = await APIKit.post('/api/layers/multi-delete/', data, {
      withCredentials: true,
    });
    return res;
  },
  duplicateLayer: async (id, data) => {
    const res = await APIKit.post(`/api/layers/${id}/duplicate/`, data, {
      withCredentials: true,
    });
    return res;
  },
  duplicateAllLayers: async (id) => {
    const res = await APIKit.post(`/api/layers/${id}/duplicate-all/`);
    return res;
  },
  assignMultiTasks: async (data) => {
    const res = await APIKit.post('/api/layers/multi-assign/', data, {
      withCredentials: true,
    });
    return res;
  },
  changeOrder: async (id, newIndex, parent) => {
    const res = await APIKit.post(`api/layers/${id}/change-order/`, {
      index: newIndex,
      parent_id: parent,
    });
    return res;
  },
  getMemberships: async (id) => {
    const res = await APIKit.get(`/api/layers/${id}/memberships/`);
    return res;
  },
  getLayerUsers: async (params) => {
    const res = await APIKit.get('/api/users/', { params });
    return res;
  },
  inviteMember: async (id, data) => {
    const res = await APIKit.post(`/api/layers/${id}/invite/`, data, {
      withCredentials: true,
    });
    return res;
  },
  sendEmail: async (id, data) => {
    const res = await APIKit.post(`/api/layers/${id}/mail/`, data, {
      withCredentials: true,
    });
    return res;
  },
  getFavorites: async () => {
    const res = await APIKit.get('api/layers/favorites/');
    return res;
  },
  addFavorite: async (id) => {
    const res = await APIKit.post(`/api/layers/${id}/like/`);
    return res;
  },
  deleteFavorites: async (id) => {
    const res = await APIKit.post(`api/layers/${id}/unlike/`);
    return res;
  },
  getComment: async (id) => {
    const res = await APIKit.get(`/api/layers/${id}/comments/`);
    return res;
  },
  addComment: async (id, data) => {
    const res = await APIKit.post(`/api/layers/${id}/comments/`, data, {
      withCredentials: true,
    });
    return res;
  },
  editComment: async (layer, comment, data) => {
    const res = await APIKit.patch(
      `/api/layers/${layer}/comments/${comment}/`,
      data,
      { withCredentials: true },
    );
    return res;
  },
  likeComment: async (layer, comment, data) => {
    const res = await APIKit.post(
      `/api/layers/${layer}/comments/${comment}/like/`,
      data,
      { withCredentials: true },
    );
    return res;
  },
  deleteComment: async (layer, comment) => {
    const res = await APIKit.delete(
      `/api/layers/${layer}/comments/${comment}/`,
      { withCredentials: true },
    );
    return res;
  },
  collapseLayer: async (id, data) => {
    const res = await APIKit.post(`/api/layers/${id}/collapse/`, data, {
      withCredentials: true,
    });
    return res;
  },
  expandLayer: async (id, data) => {
    const res = await APIKit.post(`/api/layers/${id}/expand/`, data, {
      withCredentials: true,
    });
    return res;
  },
  getTemplates: async () => {
    const res = await APIKit.get('/api/layers/templates/', {
      withCredentials: true,
    });
    return res;
  },
  duplicateTemplate: async (data) => {
    const res = await APIKit.post('/api/layers/duplicate-template/', data, {
      withCredentials: true,
    });
    return res;
  },

  importFile: async (projectId, file) => {
    const res = await APIKit.post(
      `api/layers/${projectId}/import/layer/`,
      file,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      },
    );
    return res;
  },
  extractFile: async (id, file) => {
    const res = await APIKit.post(`api/layers/${id}/extract/layers/`, file, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
    return res;
  },
  getLengthSharedUnread: async () => {
    const res = await APIKit.get('api/layers/shared/with-me/unread/');
    return res;
  },
  getAllAssignments: async () => {
    const res = await APIKit.get('/api/users/assignees/');
    return res;
  },
  getAllPlans: async () => {
    const res = await APIKit.get('/api/plans/');
    return res;
  },
  payment: async (data) => {
    const res = await APIKit.post('/api/payments/', data);
    return res;
  },
  multiInvite: async (id, params) => {
    const res = await APIKit.post(`api/layers/${id}/multi-invite/`, params);
    return res;
  },
  focusLayer: async (id) => {
    const res = await APIKit.post(`api/layers/${id}/focus/`);
    return res;
  },
  unfocusLayer: async (id) => {
    const res = await APIKit.post(`api/layers/${id}/unfocus/`);
    return res;
  },
};

export default dataServices;
