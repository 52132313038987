/* eslint-disable jsx-a11y/no-autofocus */
import { useContext, useState } from 'react';
import '../../styles/tagMenu.scss';
import { colorPanel } from '../../constants/arrays';
import { SvgSelector } from '../../helper/svg-selector';
import { DataContext } from '../../context/DataContext';
import { nodeColors } from '../../constants/colors';
import dataServices from '../../services/dataServices';
import AnimationSlide from './AnimationSlide';
import ProOptionCard from '../ProAccount/ProOptionCard';

function TagMenu({ task, directReq, onHandleVertical, vertical }) {
  const { addToQueue, treeData, dispatch, isProUser, boardId } =
    useContext(DataContext);
  const myTask = treeData[task];
  const multiSelect =
    onHandleVertical && task.map((i) => treeData[i]?.board_id);
  const parentBoard = !onHandleVertical
    ? treeData[boardId] ||
      treeData.find((i) => i.id === treeData[task].board_id)
    : treeData[multiSelect[0]];

  const [isEdit, setIsEdit] = useState(null);
  const [editValue, setEditValue] = useState('');
  const [taskColor, setTaskColor] = useState(myTask?.color || null);
  const [openProMenu, setOpenProMenu] = useState(false);

  const editHandler = (color) => {
    setIsEdit(color);
    setEditValue(parentBoard[color]);
  };

  const changeTagName = (value, tag) => {
    if (!directReq)
      addToQueue('update', { id: parentBoard.id, [tag.color]: value });
    else dataServices.updateLayers(parentBoard.id, { [tag.color]: value });
    parentBoard[tag.color] = value;
    dispatch({ type: 'setTree', payload: treeData });
    setIsEdit(null);
  };

  const clearTags = () => {
    colorPanel.forEach((i) => {
      parentBoard[i.color] = i.setColor;
      return parentBoard;
    });
    if (!directReq) addToQueue('update', { id: parentBoard.id, parentBoard });
    else dataServices.updateLayers(parentBoard.id, parentBoard);
    dispatch({ type: 'setTree', payload: treeData });
  };

  const changeColor = (color) => {
    if (isEdit) return;
    if (onHandleVertical) {
      onHandleVertical('color', color);
      return;
    }
    if (!directReq) addToQueue('update', { id: task, color });
    else dataServices.updateLayers(task, { color });
    myTask.color = color;
    dispatch({ type: 'setTree', payload: treeData });
    setTaskColor(color);
  };

  const editTag = (e, tag) => {
    e.stopPropagation();
    if (isProUser) {
      editHandler(tag.color);
      return;
    }
    setOpenProMenu(true);
  };

  return (
    <div className="float-menu-tag-container">
      <div className="header-wrapper font-14-med">
        <div className="left-side">Add Tag</div>
        <div className="right-side" onClick={clearTags}>
          Clear
        </div>
      </div>
      <div className="content-wrapper cursor-pointer">
        {colorPanel.map((tag) => {
          return (
            <div
              key={tag.title + tag.color}
              style={{
                backgroundColor: nodeColors[[tag.setColor]],
              }}
              className={`tag-item font-14-med${
                tag.disabled ? ' cursor-pointer' : ''
              }`}
              onClick={() => changeColor(tag.setColor)}
            >
              <span className="text-area">
                <div className="title-wrapper">
                  {isEdit === tag.color ? (
                    <input
                      autoFocus
                      className="font-14-med"
                      value={editValue}
                      onChange={(e) => setEditValue(e.target.value)}
                      onKeyDown={(e) =>
                        e.code === 'Enter'
                          ? changeTagName(e.target.value, tag)
                          : null
                      }
                      onBlur={(e) => changeTagName(e.target.value, tag)}
                      maxLength={17}
                    />
                  ) : (
                    <div className="flex-row">
                      <div className="font-14-med clickable-item">
                        {parentBoard[tag.color]}
                      </div>
                      <div
                        className="edit-item tooltip"
                        onClick={(e) => editTag(e, tag)}
                      >
                        {SvgSelector('pencil', 'icon-pencil')}
                      </div>
                    </div>
                  )}

                  {isEdit === tag.color && (
                    <div
                      className="clear-item cursor-pointer"
                      onClick={() => changeTagName(tag.color, tag)}
                    >
                      {SvgSelector('closeCircle')}
                    </div>
                  )}
                  {isEdit !== tag.color && taskColor === tag.setColor && (
                    <div className="apply-item cursor-pointer">
                      {SvgSelector('checkMark')}
                    </div>
                  )}
                </div>
              </span>
            </div>
          );
        })}
      </div>
      <AnimationSlide
        openMenu={setOpenProMenu}
        show={openProMenu}
        state={`${vertical ? 'rightToLeft' : 'leftToRight'}`}
      >
        <div
          className={`${vertical ? 'pro-option-card-tag-vertical' : 'pro-option-card-tag'}`}
        >
          <ProOptionCard
            closeMenu={setOpenProMenu}
            proText="Want to create your tegs? Sign up for a plan level right now!"
          />
        </div>
      </AnimationSlide>
    </div>
  );
}

export default TagMenu;
