import { useContext, useState } from 'react';
import { colorPanel } from '../../constants/arrays';
import '../../styles/tagMenu.scss';
import { DataContext } from '../../context/DataContext';
import { nodeColors } from '../../constants/colors';
import { SvgSelector } from '../../helper/svg-selector';
import dataServices from '../../services/dataServices';

function ShareTagMenu({ task, showNotify }) {
  const { setUnlockModal, treeData, dispatch, boardId } =
    useContext(DataContext);
  const user = JSON.parse(localStorage.getItem('user'));
  const myTask = treeData[task];
  const [taskColor, setTaskColor] = useState(myTask?.color || null);

  const changeColor = (color) => {
    dataServices.updateLayers(task, { color });
    myTask.color = color;
    dispatch({ type: 'setTree', payload: treeData });
    setTaskColor(color);
  };

  const editTag = () => {
    if (!treeData[boardId].is_board_root && user) {
      showNotify({
        text: "You don't have permission",
        persist: false,
        action: null,
        hasButton: true,
        actionText: 'Okay',
      });
    } else {
      setUnlockModal(true);
    }
  };
  return (
    <div className="float-menu-tag-container">
      <div className="header-wrapper font-14-med">
        <div className="left-side">Add Tag</div>
      </div>
      <div className="content-wrapper cursor-pointer">
        {colorPanel.map((tag) => {
          return (
            <div
              key={tag.title + tag.color}
              style={{
                backgroundColor: nodeColors[[tag.setColor]],
              }}
              className={`tag-item font-14-med${
                tag.disabled ? ' cursor-pointer' : ''
              }`}
              onClick={() => changeColor(tag.setColor)}
            >
              <span className="text-area">
                <div className="title-wrapper">
                  <div className="flex-row">
                    <div className="font-14-med clickable-item">
                      {treeData[boardId].board_color_tags[tag.setColor].replace(
                        '_',
                        ' ',
                      )}
                    </div>
                    <div className="edit-item tooltip" onClick={editTag}>
                      {SvgSelector('pencil', 'icon-pencil')}
                    </div>
                  </div>
                  {taskColor === tag.setColor && (
                    <div className="apply-item cursor-pointer">
                      {SvgSelector('checkMark')}
                    </div>
                  )}
                </div>
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ShareTagMenu;
