import { colors } from '../../constants';
import { floatMenuStatus } from '../../constants/arrays';
import { SvgSelector } from '../../helper/svg-selector';
import dataServices from '../../services/dataServices';
import AnimationSlide from '../Elements/AnimationSlide';
import StatusMenu from '../Elements/StatusMenu';

export default function BoardStatus({
  status,
  statusSize,
  updateTree,
  setShowStatusMenu,
  showStatusMenu,
  node,
  userAccess,
}) {
  const user = JSON.parse(localStorage.getItem('user'));

  const st = floatMenuStatus[status];
  if (!st || status === 'NO_STATUS') return null;

  const changeHandler = (type, i) => {
    updateTree(type, i, undefined, undefined, node);
    setShowStatusMenu(false);
    if (user) dataServices.unfocusLayer(node);
  };
  const changeStatusNode = (item) => {
    if (item.value === status) {
      changeHandler('status', 'NO_STATUS');
    } else {
      changeHandler('status', item.value);
    }
  };

  const openMenu = () => {
    if (user) dataServices.focusLayer(node);
    setShowStatusMenu(!showStatusMenu);
  };

  return (
    <div
      className={`board-status board-status-${statusSize} cursor-pointer`}
      style={{ backgroundColor: colors[st.color] }}
      onClick={openMenu}
    >
      {SvgSelector(st.label, `board-status-icon-${statusSize}`)}
      <AnimationSlide
        show={showStatusMenu && userAccess === 'WRITE'}
        openMenu={setShowStatusMenu}
        state="topToDown"
      >
        <div className="board-status-icon-menu">
          <StatusMenu handleClickItem={changeStatusNode} status={status} />
        </div>
      </AnimationSlide>
    </div>
  );
}
