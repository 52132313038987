import { Emoji } from 'emoji-picker-react';
import { startedList, textList } from '../../constants/arrays';
import '../../styles/affiliate.scss';

function AffiliateTab({ setToastInfo }) {
  const user = JSON.parse(localStorage.getItem('user'));

  const copyLink = () => {
    navigator?.clipboard?.writeText(
      `${`${document.location.origin}/accounts/ref/${user.affiliation_slug}/`}`,
    );
    setToastInfo({
      isLoading: false,
      text: 'Link copied to clipboard!',
      actionText: 'Okay',
      action: null,
    });
  };

  return (
    <div className="affiliate-container">
      {textList.map((item) => (
        <div className="affiliate-text-container" key={item.id}>
          <Emoji unified={item.firstEmoji} size="21" />
          <span className="font-18-reg" style={{ marginTop: '-4px' }}>
            {item.text}
          </span>
        </div>
      ))}
      <div>
        <span className="affiliate-text-container">
          <Emoji unified="1f3c5" size="21" />
          <span className="font-18-reg" style={{ marginTop: '-4px' }}>
            Your Statistics:
          </span>
        </span>
        <ul className="affiliate-statics">
          <li className="font-18-reg">
            Friends invited: {user.affiliates_count}
          </li>
          <li className="font-18-reg">
            Friends who became pro-users: {user.pro_affiliates_count}
          </li>
          <li className="font-18-reg">
            Tokens accrued: 💎 {user.available_tokens}
          </li>
        </ul>
      </div>
      <div className="affiliate-link-container">
        <div className="affiliate-text-container">
          <Emoji unified="1f517" size="21" />
          <span className="font-18-reg" style={{ marginTop: '-4px' }}>
            Copy your referral link:
          </span>
        </div>
        <span
          className="font-18-reg affiliate-link cursor-pointer"
          onClick={copyLink}
        >
          {document.location.origin}/accounts/ref/{user?.affiliation_slug}/
        </span>
      </div>
      <div>
        <span className="font-18-bold">How to Get Started:</span>
        <ol className="affiliate-started">
          {startedList.map((item) => (
            <li className="font-18-reg">{item.text}</li>
          ))}
        </ol>
      </div>
      <div>
        <span className="font-18-reg">
          Need assistance? Contact our support team at &nbsp;
          <span
            className="cursor-pointer affiliate-link-support"
            onClick={() => window.open('http://x.com/layer4you', '_blank')}
          >
            http://x.com/layer4you
          </span>
        </span>
        <br />
        <span className="font-16-reg">or partnerships@layer.cafe</span>
      </div>
    </div>
  );
}

export default AffiliateTab;
