import AccountTab from './AccountTab';
import AffiliateTab from './AffiliateTab';
import TeamTab from './TeamTab';

function TabContainer({ tab, setToastInfo }) {
  if (tab === 1) return <AccountTab />;
  if (tab === 2) return <TeamTab />;
  return <AffiliateTab setToastInfo={setToastInfo} />;
}
export default TabContainer;
