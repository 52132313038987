import APIKit from './http';

const accountServices = {
  login: async (data) => {
    const res = await APIKit.post('api/accounts/login/', data);
    return res;
  },
  logout: async () => {
    const res = await APIKit.get('accounts/logout/', { q: 123 });
    return res;
  },
  register: async (data) => {
    const res = await APIKit.post('api/accounts/registration/', data);
    return res;
  },
  getUser: async () => {
    const res = await APIKit.get('api/users/profile/');
    return res;
  },
  updateUser: async (params) => {
    const res = await APIKit.patch('api/users/profile/edit/', params);
    return res;
  },
  changePassword: async (params) => {
    const res = await APIKit.patch(
      'api/users/profile/change-password/',
      params,
    );
    return res;
  },
  getTotalObjects: async () => {
    const res = await APIKit.get('api/users/total-objects/');
    return res;
  },
};

export default accountServices;
