import { useEffect, useState } from 'react';
import '../../styles/switchButton.scss';

function SwitchButton({ name, onClick, clearDate, firstData, setClearDate }) {
  const [isToggled, setIsToggled] = useState(firstData);

  useEffect(() => {
    if (clearDate) {
      setIsToggled(false);
    }
    setClearDate(false);
  }, [clearDate]);

  const changeToggle = () => {
    setIsToggled(!isToggled);
    onClick(isToggled);
  };

  return (
    <label className="switch-container" htmlFor={name}>
      {}
      <input
        id={name}
        type="checkbox"
        checked={isToggled}
        value={!isToggled}
        name={name}
        onClick={changeToggle}
        className="switch-item"
      />
      <span className="switch-slider cursor-pointer" />
    </label>
  );
}

export default SwitchButton;
