import '../../styles/taskBoard.css';
import { useState } from 'react';
import Header from './Header';
import TabContainer from './TabContainer';
import Toast from '../Elements/Toast';

function Account({ setOpenAccountTab, tabNum }) {
  const [tab, setTab] = useState(tabNum);
  const [toastInfo, setToastInfo] = useState({
    text: null,
    isLoading: false,
    actionText: 'Okay',
    action: null,
  });
  return (
    <div
      className="account-container"
      onClick={(event) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
          setOpenAccountTab(false);
        }
      }}
    >
      <div className="account-wrapper">
        <Header tab={tab} setTab={setTab} openAccount={setOpenAccountTab} />
        <TabContainer tab={tab} setToastInfo={setToastInfo} />
      </div>
      {toastInfo.text && (
        <Toast toastInfo={toastInfo} setToastInfo={setToastInfo} />
      )}
    </div>
  );
}

export default Account;
