import { useContext, useState } from 'react';
import '../../styles/contextMenu.css';
import { SvgSelector } from '../../helper/svg-selector';
import { contextMenuItems } from '../../constants/arrays';
import { DataContext } from '../../context/DataContext';
import AnimationSlide from './AnimationSlide';
import Menu from './Menu';
import ModalSharing from './ModalSharing';

function ContextMenu(props) {
  const {
    node,
    delChild,
    setCopyMove,
    copyOrMoveNode,
    copyMove,
    addChild,
    shareLayer,
    showContextMenu,
    duplicate,
    treeRef,
    copyLink,
    showNotify,
  } = props;
  const user = JSON.parse(localStorage.getItem('user'));

  const [shownSubMenu, showSubMenu] = useState(false);
  const { dispatch, treeData, setUnlockModal } = useContext(DataContext);

  const itemClick = (i) => {
    switch (i) {
      case 1:
        addChild(null, true);
        break;
      case 2:
        addChild();
        break;
      case 4:
        dispatch({ type: 'boardToggle', payload: true });
        break;
      case 6:
        showSubMenu(true);
        break;
      case 8:
        setCopyMove({ item: node, state: 'copy' });
        break;
      case 9:
        setCopyMove({ item: node, state: 'move' });
        break;
      case 10:
        if (copyMove.state !== null) copyOrMoveNode();
        break;
      case 11:
        duplicate(node, treeData[node].parent);
        break;
      case 12:
        if (user) {
          delChild();
        } else {
          setUnlockModal(true);
        }
        break;
      default:
        break;
    }
    if (i !== 6 && (i !== 10 || copyMove.state !== null))
      showContextMenu(false);
  };
  const taskName =
    treeData[node]?.title && treeData[node].title[0]?.text.length > 0
      ? treeData[node].title[0].text
      : 'Untitled';

  return (
    <div className="contextMenuContainer">
      <Menu openMenu={showContextMenu}>
        <div className="contextMenuWrapper">
          {contextMenuItems.map((i) =>
            i.label === 'br' ? (
              <div className="card-board-menu-separator-container" key={i.id}>
                <div className="card-board-menu-separator" />
              </div>
            ) : (
              <div
                key={i.id}
                className={`contextMenuItemWrapper font-14-reg  ${
                  i.id === 10 && copyMove.state === null
                    ? 'disableItem'
                    : 'hover'
                }`}
                onClick={() => itemClick(i.id)}
              >
                <div className="header-menu-profile-text">{i.label}</div>
                {i.shortcut && (
                  <div className="contextMenuShortcutText font-12-reg">
                    {i.shortcut}
                  </div>
                )}
                {i.subMenu && (
                  <div className="card-board-menu-chevron">
                    {SvgSelector('chevronRight')}
                  </div>
                )}
              </div>
            ),
          )}
        </div>
        <div className="contextMenuSubMenu" style={{}}>
          <AnimationSlide
            show={shownSubMenu}
            state="leftToRight"
            openMenu={showSubMenu}
          >
            <ModalSharing
              boardName={taskName}
              copyLink={copyLink}
              node={node}
              setOpenModal={showSubMenu}
              shareLayer={shareLayer}
              showNotify={showNotify}
              treeRef={treeRef}
              type="node"
            />
          </AnimationSlide>
        </div>
      </Menu>
    </div>
  );
}

ContextMenu.displayName = 'FloatMenu';
export default ContextMenu;
