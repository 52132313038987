import { useParams, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import Dashboard from '../components/Main/Dashboard';
import Editor from '../components/Main/Editor';
import { DataContext } from '../context/DataContext';
import Loading from '../components/Elements/Loading';
import accountServices from '../services/accountServices';
import Toast from '../components/Elements/Toast';
import useNetwork from '../hooks/useNetwork';
import useSocket from '../hooks/useSocket';
import { theme } from '../constants/colors';
import dataServices from '../services/dataServices';
import useKeyPress from '../hooks/useKeyPress';

let layerType;
let layerId;
let fetchingData = false;
let userInfo;
function Layer() {
  layerId = useParams().id;

  const { isError } = useSocket();
  const navigate = useNavigate();
  const network = useNetwork();
  const [dataReady, setDataReady] = useState(false);
  const [layerInfo, setLayerInfo] = useState(null);
  const [descendantsInfo, setDescendantsInfo] = useState(null);
  const {
    setProjectArr,
    updatePrjArr,
    setLayers,
    setBoardId,
    setStartNode,
    togoMode,
    wsError,
    setWsError,
    isLoading: busyQueue,
    setLoadingBoard,
    selectedTheme,
    SetRecentLayer,
    httpError,
    layers,
    objectOffset,
    loadingPage,
    setLoadingPage,
    firstRender,
  } = useContext(DataContext);
  const [toastInfo, setToastInfo] = useState({
    text: null,
    isLoading: false,
    actionText: 'Retry',
    action: null,
  });
  const [hasMore, setHasMore] = useState(false);

  useKeyPress((id) => (id === 'bugReport' ? bugReportHandler() : null));

  const getLayer = () => {
    setDataReady(false);
    setDescendantsInfo(null);
    dataServices
      .getLayerById(layerId)
      .then((res) => {
        setDataReady(true);
        setLayerInfo(res.data);
        layerType = res.data.layer_type;
        if (layerType === 'PROJECT') {
          getDashboard();
          getRecentDashboard();
        } else getBoard();
      })
      .catch((err) => {
        if (err.response?.status === 403) getUser(true);
      });
  };

  const getDashboard = () => {
    setDataReady(false);
    dataServices
      .getLayerChildren(layerId, {
        layer_type__in: ['PROJECT', 'BOARD'].toString(),
        count: 10,
        offset: 0,
      })
      .then((res) => {
        setLayers(res.data);
        setLoadingPage(false);
        setBoardId(layerId);
        setStartNode(layerId);
        setDataReady(true);
        fetchingData = false;
        if (res.data.length <= 9) {
          setHasMore(true);
        }
        getUser();
      });
  };

  const getLimitDashBoard = () => {
    if (loadingPage || layerType !== 'PROJECT') return;
    setLoadingPage(true);
    dataServices
      .getLayerChildren(layerId, {
        layer_type__in: ['PROJECT', 'BOARD'].toString(),
        count: 10,
        offset: objectOffset,
      })
      .then((res) => {
        setLayers([...layers, ...res.data]);
        setLoadingPage(false);
        if (res.data.length < 10) {
          setHasMore(true);
        }
      });
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    if (!hasMore) {
      getLimitDashBoard();
    }
  }, [objectOffset]);

  const getRecentDashboard = () => {
    setLoadingBoard(true);
    dataServices
      .getRecentLayerChildren()
      .then((res) => {
        SetRecentLayer(res.data);
        setLoadingBoard(false);

        // setLayers(res.data);
        // setBoardId(layerId);
        // setStartNode(layerId);
        // setDataReady(true);
        // fetchingData = false;
      })
      .catch((err) => {
        setToastInfo({
          text: `Recent data fetch error: ${err.message}`,
          action: null,
          actionText: 'Ok',
        });
        setLoadingBoard(false);
      });
  };

  const getBoard = () => {
    dataServices
      .getLayerDescendants(layerId, {
        layer_type__in: ['TASK,FOLDER'].toString(),
      })
      .then((res) => {
        setDescendantsInfo(res.data);
        setBoardId(layerId);
        setStartNode(layerId);
        setDataReady(true);
        fetchingData = false;
      });
  };

  const getUser = (withNavigation) => {
    accountServices.getUser().then((res) => {
      if (res.data?.root) {
        localStorage.setItem('user', JSON.stringify(res.data));
        fetchingData = false;
        userInfo = res.data;
        if (withNavigation) navigate(`/${res?.data.root?.id}`);
      }
    });
  };

  const reloadPage = () => {
    window.location.reload();
  };

  const removeWsError = () => {
    setWsError({ text: null, force: false });
  };
  const reconnect = () => {
    // socket.setWs(new WebSocket('wss://i.xolbia.com/ws/'));
  };

  const getAllData = () => {
    fetchingData = true;
    setDataReady(false);
    setProjectArr({});
    updatePrjArr({});
    getLayer();
  };

  useEffect(() => {
    if (!busyQueue && !fetchingData && layerId) {
      getAllData();
    }
  }, [layerId, togoMode]);

  useEffect(() => {
    if (wsError.text) {
      setToastInfo({
        ...toastInfo,
        text: wsError.text,
        persist: wsError.persist ?? true,
        actionText: wsError.force ? 'Retry' : 'Ok',
        action: wsError.force ? reloadPage : removeWsError,
        hasButton: true,
      });
    } else if (isError) {
      setToastInfo({
        ...toastInfo,
        text: 'Connecting...',
        isLoading: false,
        actionText: 'Retry',
        persist: true,
        action: reconnect,
        hasButton: false,
      });
    } else if (!network) {
      setToastInfo({
        ...toastInfo,
        text: 'You are Offline!',
        persist: true,
        action: reloadPage,
        hasButton: false,
      });
    } else if (
      (network && toastInfo.text === 'You are Offline!') ||
      (!isError && toastInfo.text === 'Connecting...')
    ) {
      setToastInfo({ ...toastInfo, text: null });
    } else if (httpError.text) {
      setLoadingBoard(true);
      setToastInfo({
        ...toastInfo,
        text: httpError.text,
        persist: true,
        actionText: 'Retry',
        action: reloadPage,
        hasButton: true,
        isLoading: false,
      });
    }
  }, [isError, network, wsError, httpError]);

  const changeTheme = (fromColor, toColor) => {
    const getColor = document.querySelector(':root');
    getColor.style.setProperty(fromColor, toColor);
  };

  const bugReportHandler = async () => {
    await Sentry.flush(2000);
    console.log('User Feedback', userInfo);
    Sentry.captureMessage(`User Feedback at ${new Date().toISOString()}`);
    setToastInfo({
      ...toastInfo,
      text: 'A bug report submitted! 🐛',
      persist: false,
      hasButton: false,
      isLoading: false,
    });
  };

  useEffect(() => {
    if (selectedTheme) {
      Object.entries(theme[selectedTheme]).map((keyTheme) => {
        changeTheme(keyTheme[0], keyTheme[1]);
        return null;
      });
    }
  }, [selectedTheme]);

  if (!layerInfo?.layer_type) return <Loading />;
  return (
    <div>
      {layerInfo?.layer_type === 'PROJECT' ? (
        <Dashboard
          currentProject={layerInfo}
          setCurrentProject={setLayerInfo}
          RefreshLayer={getLayer}
          RefreshRecentLayers={getRecentDashboard}
          layerLoading={!dataReady}
          boardID={layerId}
          setHasMore={setHasMore}
          hasMore={hasMore}
          getLimitDashBoard={getLimitDashBoard}
        />
      ) : (
        <Editor
          boardId={layerId}
          loading={!dataReady}
          setLoading={setDataReady}
          layerInfo={layerInfo}
          descendantsInfo={descendantsInfo}
          RefreshLayer={getLayer}
        />
      )}
      {toastInfo.text && (
        <Toast toastInfo={toastInfo} setToastInfo={setToastInfo} wsAndHttp />
      )}
    </div>
  );
}
export default Layer;
