import { useContext, useEffect, useState } from 'react';
import { taskBoardFilter } from '../../constants/arrays';
import '../../styles/taskBoard.scss';
import AnimationSlide from './AnimationSlide';
import StatusMenu from './StatusMenu';
import { SvgSelector } from '../../helper/svg-selector';
import FilterTag from './FilterTag';
import MenuSelectMember from './MenuSelectMember';
import { DataContext } from '../../context/DataContext';
import SmartFilter from '../Dashboard/SmartFilter';
import { objSubtraction, deadlineCal } from '../../helper/functions';
import dataServices from '../../services/dataServices';
import DatePicker from './DatePicker';

function TaskViewFilter() {
  const userData = JSON.parse(localStorage.getItem('user'));

  const { setTaskFilter, taskFilter, boardId } = useContext(DataContext);
  const [openMenu, setOpenMenu] = useState('');
  const [user, setUser] = useState([]);
  const [assignedUsers, setAssignedUsers] = useState([]);

  useEffect(() => {
    setTaskFilter({ tag: [], status: [], assignments: {} });
  }, []);

  const getBoardMembers = () => {
    dataServices.getMemberships(boardId).then((res) => {
      const members = objSubtraction(res.data, assignedUsers);
      setUser(members);
    });
  };

  useEffect(() => {
    if (userData) {
      getBoardMembers();
    }
  }, []);

  const onClickFilter = (item) => {
    setOpenMenu(item);
  };

  const selectFilterStatus = (status) => {
    setTaskFilter((prev) => {
      if (prev.status.includes(status.value)) {
        return prev;
      }
      return {
        ...prev,
        status: [...prev.status, status.value],
      };
    });
  };

  const selectFilterAssign = (info) => {
    if (taskFilter.assignments.userNames) {
      setTaskFilter((prev) => {
        if (prev.assignments.userId.includes(info.id)) {
          return prev;
        }
        return {
          ...prev,
          assignments: {
            ...prev.assignments,
            userId: [...prev.assignments.userId, info.id],
            userNames: [...prev.assignments.userId, info.id],
            userEmail: [...prev.assignments.userEmail, info.email],
            display_userNames: [...prev.assignments.userEmail, info.email],
          },
        };
      });
    } else {
      setTaskFilter((prev) => ({
        ...prev,
        assignments: {
          ...prev.assignments,
          userId: [info.id],
          userNames: [info.id],
          userEmail: [info.email],
          display_userNames: [info.email],
        },
      }));
      setAssignedUsers((old) => [...old, user]);
    }
  };

  const selectFilterDeadLine = (tStartDate, tDueDate) => {
    let startTemp;
    startTemp = tStartDate;
    if (tDueDate && !tStartDate) {
      startTemp = tDueDate;
      const givenDate = new Date(startTemp);
      const newDate = new Date(givenDate.getTime());
      const sameDate = newDate.toISOString();

      const lastDate = new Date(givenDate.getTime() - 24 * 60 * 60 * 1000);
      const lastSameDate = lastDate.toISOString();

      setTaskFilter((prev) => ({
        ...prev,
        due_date: {
          ...prev.due_date,
          due_timeGt: [lastSameDate],
          due_timeIt: [sameDate],
          display_date: [
            startTemp ? deadlineCal(tDueDate)[0] : '',
            tDueDate ? deadlineCal(sameDate)[0] : '',
          ],
        },
      }));
    }
    if (tDueDate && tStartDate) {
      const sT = new Date(tStartDate.getTime());
      const startDate = sT.toISOString();
      const dT = new Date(tDueDate.getTime());
      const dueDate = dT.toISOString();
      setTaskFilter((prev) => ({
        ...prev,
        due_date: {
          ...prev.due_date,
          due_timeGt: [startDate],
          due_timeIt: [dueDate],
          display_date: [
            startTemp ? deadlineCal(tStartDate)[0] : '',
            tDueDate ? deadlineCal(tDueDate)[0] : '',
          ],
        },
      }));
    }
    setOpenMenu('');
  };

  const selectFilterTag = (tag) => {
    setTaskFilter((prev) => {
      if (prev.tag.includes(tag.setColor)) {
        return prev;
      }
      return {
        ...prev,
        tag: [...prev.tag, tag.setColor],
      };
    });
  };

  return (
    <div className="task-board-container">
      <div className="task-board-header-container">
        <div style={{ width: '33%' }} />
        {taskBoardFilter.slice(2).map((i) => (
          <div style={{ width: i.grid }}>
            <div
              className="font-14-med task-board-header-item cursor-pointer tooltip"
              onClick={() => onClickFilter(i.text)}
              style={{ marginLeft: i.id === 3 ? '13px' : '' }}
            >
              {i.text}
              {SvgSelector('chevronDown', 'task-board-header-item-icon')}
              <span className={`tooltiptext font-10-med ${i.tooltipClass}`}>
                {i.tooltiptext}
              </span>
              {i.id === 3 ? (
                <AnimationSlide
                  openMenu={setOpenMenu}
                  show={openMenu === 'Deadline'}
                  state="topToDown"
                >
                  <div className="select-date-filter-pos">
                    <DatePicker node={1} onClose={selectFilterDeadLine} />
                  </div>
                </AnimationSlide>
              ) : i.id === 4 ? (
                <AnimationSlide
                  openMenu={setOpenMenu}
                  show={openMenu === 'Status'}
                  state="topToDown"
                >
                  <div className="select-status-filter-pos">
                    <StatusMenu handleClickItem={selectFilterStatus} />
                  </div>
                </AnimationSlide>
              ) : i.id === 5 ? (
                <AnimationSlide
                  openMenu={setOpenMenu}
                  show={openMenu === 'Tags'}
                  state="topToDown"
                >
                  <div className="select-tags-filter-pos">
                    <FilterTag onSelectItem={selectFilterTag} />
                  </div>
                </AnimationSlide>
              ) : i.id === 6 ? (
                <AnimationSlide
                  openMenu={setOpenMenu}
                  show={openMenu === 'Members'}
                  state="topToDown"
                >
                  <div className="select-members-filter-pos">
                    <MenuSelectMember
                      data={user}
                      setFilterTask={selectFilterAssign}
                    />
                  </div>
                </AnimationSlide>
              ) : null}
            </div>
          </div>
        ))}
      </div>
      <div style={{ marginLeft: '15px' }}>
        <div className="task-board-title-filter">
          <SmartFilter setTaskFilter={setTaskFilter} taskFilter={taskFilter} />
        </div>
      </div>
    </div>
  );
}

export default TaskViewFilter;
