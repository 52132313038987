import { useState, useEffect, useContext, useRef } from 'react';
import { Emoji } from 'emoji-picker-react';
import { v4 as uuidv4 } from 'uuid';
import { DataContext } from '../../context/DataContext';
import FloatMenu from './FloatMenu';
import '../../styles/tree.css';
import { SvgSelector } from '../../helper/svg-selector';
import Note from './Note';
import Avatar from './Avatar';
import Deadline from '../Board/Deadline';
import BoardStatus from '../Board/BoardStatus';
import DraggableNode from '../Board/DraggableNode';
import NodeConnectionLine from './NodeConnection';
import NodeConnectionLineLevels from './NodeConnectionLevels';
import { layerProps } from '../../constants/objects';
import ContextMenu from './ContextMenu';
import { nodeColors } from '../../constants/colors';
import CustomBoardEmoji from '../../assets/png/board-custom-icon.png';
import CustomProjectEmoji from '../../assets/png/project-custom-icon.png';
import dataServices from '../../services/dataServices';

let copyMove = { item: null, state: null };
let timer = null;
function TreeNode({
  node,
  MainNode,
  itemEls,
  setRefreshUI,
  inputFocused,
  selectNode,
  setInputFocus,
  hasProject,
  showNotify,
  unDelete,
  deleteNode,
  shareLayer,
  scrollRef,
  copyLink,
  treeRef,
}) {
  const {
    treeData,
    board,
    boardId,
    dispatch,
    updateTree,
    selectNodeHandler,
    setCurrNode,
    filterOptions,
    maxProjectWidth,
    addToQueue,
    setRefresh,
    userAccess,
    calculateProjectsWidth,
    shownNote,
    shownContextMenu,
    showContextMenu,
    showNote,
    showAssignmentMenu,
    inviteModal,
    shownAssignmentIcon,
    expandStatus,
    treeMultiSelect,
    treeMultiSelectList,
    updateSelectedItems,
    togoMode,
    startNode,
    ctrlKeyPressed,
    treeMultiSelectByKey,
    setTreeMultiSelectByKey,
    levelsMode,
    changedHandSkin,
    setBlockParent,
    setCtrlKeyPressed,
    setFocusModeKey,
    collaborateMsg,
    setCollaborateMsg,
  } = useContext(DataContext);
  const inputRef = useRef(null);
  const wrapperRef = useRef(null);
  const plusBtnRef = useRef(null);
  const floatMenuRef = useRef(null);
  const caretPos = useRef();
  const isNameChanged = useRef(null);
  const user = JSON.parse(localStorage.getItem('user'));
  const checkSharingMode = node !== boardId;

  useOutsideAlerter(wrapperRef, plusBtnRef, floatMenuRef);
  // const clickOutSide = useOutsideAlerter(wrapperRef);
  const [nameTracker, setNameTracker] = useState(
    treeData[node]?.title[0]?.text,
  );
  const span = useRef();
  // const [refreshID, setRefresh] = useState(1);
  const [stepExpand, setLocalStepExpand] = useState(
    treeData[node]?.expansion || false,
  );
  const [collapse, setCollapse] = useState(treeData[node]?.collapse);
  const [showStatusMenu, setShowStatusMenu] = useState(false);

  const isFocused = treeData[node]?.isFocused;
  const isTemplate = treeData[boardId]?.template;
  const order = treeData[node]?.order;
  const parent = treeData[node]?.parent;
  const color = treeData[node]?.color;
  const name = treeData[node]?.title[0]?.text;
  const emoji = treeData[node]?.emoji;
  const schedule = treeData[node]?.due_time;
  const status = treeData[node]?.status;
  const hasNote = treeData[node]?.has_note;
  const isFolder = treeData[node]?.layer_type === 'FOLDER';
  const commentsCounts = treeData[node]?.comments_count;
  const isProject = hasProject ? treeData[node]?.parent === MainNode : false;
  let fontSize;
  let itemSize;
  let nodeHeight;
  let fontSchedule;
  let statusSize;
  const boardCustomEmoji = emoji === 'layer-board';
  const projectCustomEmoji = emoji === 'layer-project';

  useEffect(() => {
    if (inputFocused === node && !board) inputRef?.current?.focus();
  });
  useEffect(() => {
    if (inviteModal) {
      setInputFocus(-1);
      inputRef.current.contentEditable = false;
      // inputRef?.current?.blur();
    }
  }, [inviteModal]);

  const inputWrapperClickHandler = () => {
    if (isTemplate) return;
    if (user) dataServices.focusLayer(node);
    setShowStatusMenu(false);
    showNote(false);
    showContextMenu(false);
    if ((ctrlKeyPressed || treeMultiSelect) && user) {
      setTreeMultiSelectByKey(true);
      updateSelectedItems(node);
    } else {
      setTreeMultiSelectByKey(false);
      if (userAccess === 'WRITE' && inputRef.current && !isFocused)
        inputRef.current.contentEditable = true;
      setInputFocus(node);
      setBlockParent(node);
      selectNode(node);
    }
  };

  useEffect(() => {
    setRefresh((old) => old + 1);
    if (Object.keys(treeData).length === 2) {
      inputWrapperClickHandler();
    }
    // setNameTracker(treeData[node]?.title)
  }, []);

  useEffect(() => {
    setCollaborateMsg(false);
    if (!collaborateMsg && inputFocused === node) {
      if (!inputRef.current) return;
      setTimeout(() => {
        if (!isFocused) inputRef.current.contentEditable = true;
        inputRef?.current.focus();
        setCaret(inputRef?.current, caretPos?.current);
      }, 500);
    }
  }, [collaborateMsg]);

  // useEffect(() => {
  //   setLocalStepExpand(treeData[node]?.expansion);
  // }, [refresh]);

  useEffect(() => {
    setCollapse(treeData[node]?.collapse);
    setRefresh((old) => old + 1);
  }, [expandStatus]);

  const hideDoneStatus = () => {
    const filterResult = treeData[node].status === filterOptions.hideDone[0];
    return filterResult;
  };

  useEffect(() => {
    setCaret(inputRef?.current, caretPos?.current);
    inputRef?.current?.focus();
    if (isProject && !hideDoneStatus()) {
      // setProjectArr({ ...projectArr, [node]: span.current?.clientWidth });
      // calculateProjectsWidth([node], span.current?.clientWidth);
      const extraWidth =
        treeData[node].assignments?.length > 1
          ? 60
          : treeData[node].assignments?.length > 0
            ? 30
            : 0;
      calculateProjectsWidth([node], span.current.clientWidth + extraWidth);
    }
    setRefresh((old) => old + 1);
    // treeData[node].title = name;
  }, [nameTracker]);

  useEffect(() => {
    setNameTracker(name);
  }, [name]);

  useEffect(() => {
    if (!inputRef?.current) return;
    if (inputFocused === node && userAccess === 'WRITE' && !isFocused)
      inputRef.current.contentEditable = true;
    else inputRef.current.contentEditable = false;
  }, [inputFocused]);

  if (!treeData[node]) return null;

  const getLevelNode = () => {
    // PROJECT
    if (isProject) {
      fontSize = 'font-18-med';
      fontSchedule = 'font-12-med';
      statusSize = '36';
      itemSize = '24';
      nodeHeight = '';
      return 'first-level';
    }
    // LEVEL_1
    if (treeData[treeData[node]?.parent]?.parent === MainNode || !levelsMode) {
      fontSize = 'font-14-med tree-node-first-level';
      fontSchedule = 'font-12-med';
      statusSize = '36';
      itemSize = '24';
      nodeHeight = 'tree-node-first-level-height';
      return 'first-level';
    }
    // LEVEL_2
    if (
      treeData[treeData[treeData[node]?.parent]?.parent]?.parent === MainNode
    ) {
      fontSize = 'font-12-med tree-node-second-level';
      fontSchedule = 'font-10-med';
      statusSize = '28';
      itemSize = '16';
      nodeHeight = 'tree-node-second-level-height';
      return 'second-level';
    }
    // LEVEL_3
    fontSize = 'font-10-med tree-node-third-level';
    fontSchedule = 'font-10-med';
    statusSize = '22';
    itemSize = '12';
    nodeHeight = 'tree-node-third-level-height';
    return 'third-level';
  };
  const nodeLevel = getLevelNode();

  const calculateChildren = () => {
    let count = 0;
    const cal = (n) => {
      if (treeData[n]?.order?.length > 0) {
        count += treeData[n]?.order?.length || 0;
        treeData[n]?.order?.map((i) => cal(i));
      }
    };
    cal(node);
    return count.toString();
  };

  const nestedChildren = calculateChildren();
  function useOutsideAlerter(ref1, ref2, ref3) {
    useEffect(() => {
      function handleClickOutside(event) {
        const c1 = ref1.current && !ref1.current.contains(event.target);
        const c2 = ref2.current ? !ref2.current.contains(event.target) : true;
        const c3 = ref3.current && !ref3.current.contains(event.target);
        if (c1 && c2 && c3) {
          setInputFocus(-1);
          if (user) dataServices.unfocusLayer(node);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref1, ref2, ref3]);
  }

  const disableEdit = () => {
    if (inputRef.current) inputRef.current.contentEditable = false;
  };

  const ExpandChildren = () => {
    if (stepExpand) {
      const tempData = treeData;
      Object.keys(tempData).map(
        (k) => {
          if (tempData[k].parent === node) tempData[k].collapse = true;
          return tempData[k].collapse;
        },
        // return (i.expand = false);
      );

      dispatch({ type: 'setTree', payload: tempData });
      // setRefresh((old) => !old);
    }
  };
  const addChildExpand = (bool) => {
    const tempData = treeData;
    const cal = (n) => {
      if (treeData[n]?.order?.length > 0) {
        treeData[n]?.order?.forEach((i) => {
          if (bool === false) tempData[i].collapse = bool;
          else if (tempData[tempData[i].parent].expansion)
            tempData[i].collapse = false;
          else tempData[i].collapse = bool;
        });
        treeData[n]?.order?.map((child) => cal(child));
      }
    };
    cal(node);
    dispatch({ type: 'setTree', payload: tempData });
  };
  const changeChildExpandType = (bool) => {
    const tempData = treeData;
    tempData[node].expansion = bool;
    const cal = (n) => {
      if (treeData[n]?.order?.length > 0) {
        treeData[n]?.order?.map((i) => {
          tempData[i].expansion = bool;
          return tempData[i];
        });
        treeData[n]?.order?.map((child) => cal(child));
      }
    };
    cal(node);
    dispatch({ type: 'setTree', payload: tempData });
    setRefresh((old) => old + 1);
  };

  const changeChildrenId = (newNode) => {
    const tempData = treeData;
    const layers = [{ ...newNode, parent_id: newNode.parent }];
    tempData[newNode.id] = newNode;
    // addToQueue('create', tempData[newNode.id]);
    const changeId = (n) => {
      const newN = n;
      if (n.order?.length > 0) {
        n.order?.map((i) => {
          const childNewID = uuidv4();

          const index = n?.order.indexOf(i);
          newN.order[index] = childNewID;
          tempData[childNewID] = JSON.parse(JSON.stringify(tempData[i]));
          tempData[childNewID].id = childNewID;
          tempData[childNewID].parent = n.id;

          tempData[childNewID].previous_id = newN.order[index - 1] || null;
          tempData[childNewID].previous = newN.order[index - 1] || null;
          layers.push({
            ...tempData[childNewID],
            parent_id: tempData[childNewID].parent,
          });
          // addToQueue('create', tempData[childNewID]);
          return changeId(tempData[childNewID]);
          // );
        });
      }
    };
    changeId(newNode);
    return { tempData, layers };
    // dispatch({ type: "setTree", payload: tempData });
  };

  const addChild = (event, sibling = false) => {
    const tempData = treeData;
    const nodeParent =
      boardId === node ? tempData[boardId].id : tempData[node].parent;
    const parentOrder = tempData[nodeParent]?.order;
    let nodeColor = 'BLUE';
    if (tempData[boardId].is_board_root) {
      if (sibling && treeData[node]?.parent === MainNode) nodeColor = 'PINK';
    }

    const nodeOrder = tempData[node].order;
    const idx = tempData[nodeParent].order.indexOf(node);
    const previous = sibling ? node : nodeOrder[nodeOrder.length - 1];
    const newChildID = uuidv4();
    const newChild = {
      ...layerProps,
      id: newChildID,
      title: [{ text: '' }],
      layer_type: 'TASK',
      parent: sibling ? nodeParent : node,
      color: nodeColor,
      expansion: tempData[node].expansion,
      order: [],
      previous,
      previous_id: previous,
      board_id: boardId,
      idx: sibling ? idx + 1 : tempData[node].order.length,
      status: 'NO_STATUS',
    };

    if (sibling) parentOrder.splice(idx + 1, 0, newChildID);

    tempData[sibling ? nodeParent : node].order = tempData[
      sibling ? nodeParent : node
    ].order
      ? sibling
        ? parentOrder
        : [...tempData[node].order, newChildID]
      : [newChildID];

    tempData[newChildID] = newChild;
    if (sibling) tempData[newChildID].index = idx + 1;
    addToQueue('create', tempData[newChildID]);
    setCurrNode(newChildID);
    selectNode(newChildID);
    setBlockParent(newChildID);
    dispatch({ type: 'setTree', payload: tempData });
    setInputFocus(newChildID);
  };

  const duplicateNode = (from, to) => {
    let tempData = treeData;
    let layers = [];
    let copyNode = {};
    const newChildID = uuidv4();
    const idx = tempData[to].order.indexOf(node);
    copyNode = JSON.parse(JSON.stringify(tempData[from]));
    copyNode.id = newChildID;
    copyNode.parent = to;
    copyNode.previous_id = idx >= 0 ? from : null;
    copyNode.previous = idx >= 0 ? from : null;
    ({ tempData, layers } = changeChildrenId(copyNode));
    const layersToSend = {
      pk: newChildID,
      layers,
    };
    tempData[to].order = tempData[to].order || [];
    if (idx >= 0) tempData[to].order.splice(idx + 1, 0, newChildID);
    else tempData[to].order = [newChildID, ...tempData[to].order];

    setCurrNode(newChildID);
    dispatch({ type: 'setTree', payload: tempData });
    addToQueue('copy', {
      node: from,
      newParent: to,
      data: layersToSend,
      idx: idx >= 0 ? idx + 1 : tempData[to].order.length - 1,
    });
    // setShowChildren(true);
    // setRefresh((old) => old + 1);
    setInputFocus(newChildID);
  };

  const setCopyMove = (d) => {
    copyMove = d;
  };
  const copyOrMoveNode = () => {
    if (!copyMove.item) return;
    const from = copyMove.item;
    const to = node;

    // move
    if (copyMove.state === 'move') {
      const tempData = treeData;

      //  check valid operation
      let nodeParentId = treeData[treeData[to].parent].id;
      while (nodeParentId !== startNode) {
        if (nodeParentId === from) {
          showNotify({
            isLoading: false,
            text: 'This operation is not allowed!',
            actionText: 'Okay',
            action: null,
          });
          return;
        }
        nodeParentId = treeData[treeData[nodeParentId].parent].id;
      }

      const nodeParent = tempData[from].parent;
      const oldOrder = [...tempData[nodeParent].order];
      const idx = tempData[nodeParent].order.indexOf(from);
      tempData[nodeParent].order.splice(idx, 1);
      tempData[from].parent = to;
      tempData[to].order = tempData[to].order ? tempData[to].order : [];
      tempData[to].order.push(from);
      copyMove = { item: null, state: null };
      addToQueue('update', {
        node: from,
        id: to,
        order: tempData[to].order,
        oldParent: nodeParent,
        oldOrder,
      });
      dispatch({ type: 'setTree', payload: tempData });
    }
    // copy
    if (copyMove.state === 'copy') {
      setCtrlKeyPressed(false);
      duplicateNode(from, to);
    }
  };
  const setExpand = (bool) => {
    if (userAccess === 'WRITE' && user)
      addToQueue('collapse', { id: node, data: { collapse: bool } });
    const tempData = treeData;
    if (bool === false) ExpandChildren();
    tempData[node].collapse = !tempData[node].collapse;
    setCollapse((prev) => !prev);
    dispatch({ type: 'setTree', payload: tempData });
    setRefresh((old) => old + 1);
  };

  const setStepExpand = () => {
    changeChildExpandType(!stepExpand);
    if (stepExpand) addChildExpand(false);
    else addChildExpand(true);
    setLocalStepExpand(!stepExpand);
    addToQueue('expand', { id: node, data: { expansion: !stepExpand } });
    setRefresh((old) => old + 1);
  };

  const setColor = (code) => {
    updateTree('color', code);
  };

  const setEmoji = (_, emj) => {
    selectNodeHandler(node, treeData);
    updateTree('emoji', emj);
  };

  const setFolder = () => {
    const tempData = treeData;
    tempData[node].layer_type = isFolder ? 'TASK' : 'FOLDER';
    addToQueue('update', {
      id: tempData[node].id,
      layer_type: isFolder ? 'TASK' : 'FOLDER',
    });
    dispatch({ type: 'setTree', payload: tempData });
    setRefresh((old) => old + 1);
  };
  const nameChangeHandler = (n = null) => {
    clearInterval(timer);
    if (nameTracker === n) return;
    // const t = inputRef?.current.innerText;

    // if (t?.length > 0) {

    timer = setTimeout(() => {
      addToQueue('update', { id: treeData[node].id, title: [{ text: n }] });
      const tempData = JSON.parse(JSON.stringify(treeData));
      tempData[node].title = n;
      isNameChanged.current = false;
    }, 500);
    // addToQueue('update', { id: treeData[node].id, title: t });
    // treeData[node].title = t;
  };

  const changeName = (n) => {
    // setName(name);
    isNameChanged.current = true;
    nameChangeHandler(n);
    setNameTracker(n);
    const tempData = JSON.parse(JSON.stringify(treeData));
    tempData[node].title[0].text = n;
    dispatch({ type: 'setTree', payload: tempData });
    selectNodeHandler(node, tempData);
    setRefresh((old) => old + 1);
  };

  const editableOnChange = (e) => {
    const t = e.target.innerText.substring(0, isProject ? 140 : 70);
    caretPos.current = getCaret(inputRef.current);
    changeName(t);
  };
  const filterNode = () => {
    if (
      filterOptions.statuses.length === 0 &&
      filterOptions.colors.length === 0
    )
      return true;
    if (
      filterOptions.statuses.includes(treeData[node]?.status) ||
      filterOptions.colors.includes(treeData[node]?.color)
    ) {
      return true;
    }
    return false;
  };

  const onKeyDown = (e) => {
    const hasSelected = window.getSelection().toString();
    if (e.key === 'Enter') e.preventDefault();
    if (userAccess !== 'WRITE') return;
    if (e.ctrlKey && e.code === 'KeyC') {
      if (hasSelected) {
        copyMove = { item: null, state: null };
        return;
      }
      e.preventDefault();
      copyMove = { item: node, state: 'copy' };
    } else if (e.ctrlKey && e.code === 'KeyX') {
      if (hasSelected) {
        copyMove = { item: null, state: null };
        return;
      }
      e.preventDefault();
      copyMove = { item: node, state: 'move' };
    } else if (copyMove.state && e.ctrlKey && e.code === 'KeyV') {
      e.preventDefault();
      copyOrMoveNode();
    } else if (e.key === 'Enter') {
      e.preventDefault();
      addChild(e, checkSharingMode);
    } else if (e.key === 'Tab') {
      e.preventDefault();
      addChild(e, false);
    } else if (
      e.keyCode !== 8 &&
      inputRef.current?.textContent?.length >= (isProject ? 140 : 70)
    ) {
      e.preventDefault();
    }
  };

  const blurHandler = () => {
    // setInputFocus(-1)
    selectNodeHandler(null);
    setRefresh((old) => old + 1);
    inputRef?.current.blur();
    // if (!showFloatMenu) setInputFocus(-1)
    const t = inputRef?.current.innerText;
    if (!inviteModal && isNameChanged.current === true) {
      addToQueue('update', { id: treeData[node].id, title: [{ text: t }] });
      isNameChanged.current = false;
    }
  };

  function getCaret(el) {
    let caretAt = 0;
    const sel = window.getSelection();
    if (sel.rangeCount === 0) {
      return caretAt;
    }
    const range = sel.getRangeAt(0);
    const preRange = range.cloneRange();
    preRange.selectNodeContents(el);
    preRange.setEnd(range.endContainer, range.endOffset);
    caretAt = preRange.toString().length;
    return caretAt;
  }

  const contextMenuHandler = (e) => {
    e.preventDefault();
    showContextMenu(node);
  };

  function setCaret(el, offset) {
    try {
      if (!el?.childNodes[0]) return;
      const sel = window.getSelection();
      const range = document.createRange();
      if (!el?.childNodes[0].length) return;
      range.setStart(el?.childNodes[0], offset);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    } catch (err) {
      console.log('setCaret', err);
    }
  }

  const openStatusMenu = (item) => {
    setInputFocus(-1);
    setShowStatusMenu(item);
  };
  if (!hideDoneStatus())
    return (
      <div className="nodes tree-node-container">
        <div
          className="nodes tree-node-parent"
          id={node}
          ref={(element) => {
            const newItem = itemEls;
            newItem.current[node] = {
              element,
              parent,
              order,
            };
            return newItem.current[node];
          }}
        >
          {!togoMode &&
            (levelsMode ? (
              <NodeConnectionLineLevels itemEls={itemEls} node={node} />
            ) : (
              <NodeConnectionLine itemEls={itemEls} node={node} />
            ))}
          {!isTemplate &&
            shownContextMenu === node &&
            userAccess === 'WRITE' && (
              <ContextMenu
                showContextMenu={showContextMenu}
                delChild={() => deleteNode(node)}
                project={isProject}
                isFolder={isFolder}
                addChild={addChild}
                node={node}
                setRefresh={setRefresh}
                copyOrMoveNode={copyOrMoveNode}
                setCopyMove={setCopyMove}
                copyMove={copyMove}
                duplicate={duplicateNode}
                MainNode={MainNode}
                shareLayer={shareLayer}
                disableEdit={disableEdit}
                treeRef={treeRef}
                copyLink={copyLink}
                showNotify={showNotify}
              />
            )}
          {!isTemplate &&
            !treeMultiSelectByKey &&
            !shownContextMenu &&
            inputFocused === node && (
              <FloatMenu
                ref={floatMenuRef}
                delChild={() => deleteNode(node)}
                color={color}
                emoji={emoji}
                setColor={setColor}
                status={treeData[node]?.status}
                schedule={schedule || null}
                setEmoji={setEmoji}
                project={isProject}
                isFolder={isFolder}
                node={node}
                setRefresh={setRefresh}
                setStepExpand={setStepExpand}
                stepExpand={stepExpand}
                duplicate={duplicateNode}
                MainNode={MainNode}
                shareLayer={shareLayer}
                copyLink={copyLink}
                disableEdit={disableEdit}
                setFolder={setFolder}
                changedHandSkin={changedHandSkin}
                scrollRef={scrollRef}
                showNotify={showNotify}
                treeRef={treeRef}
              />
            )}
          {shownNote === node && <Note showNote={showNote} id={node} />}
          <div
            className="nodes tree-node-children"
            onMouseEnter={() => {
              setFocusModeKey(node);
            }}
            onMouseLeave={() => setFocusModeKey(null)}
          >
            <DraggableNode
              inputFocused={inputFocused}
              isFolder={isFolder}
              isProject={isProject}
              node={node}
              treeData={treeData}
              status={status}
              filterNode={filterNode}
              mainNode={MainNode}
              showNotify={showNotify}
              togoMode={togoMode}
            >
              {status !== 1 && !isProject && (
                <BoardStatus
                  status={status}
                  statusSize={statusSize}
                  updateTree={updateTree}
                  showStatusMenu={showStatusMenu}
                  setShowStatusMenu={openStatusMenu}
                  node={node}
                  userAccess={userAccess}
                />
              )}
              <div
                ref={wrapperRef}
                id="inputWrapper"
                className={`nodeStyle nodes ${nodeHeight} ${
                  isProject ? 'nodeProject' : ''
                }
        ${
          isFolder
            ? inputFocused === node
              ? 'nodeFolderFocus'
              : 'nodeFolder'
            : 'btnHover'
        }`}
                style={{
                  opacity: isFolder ? 0.5 : status === 'DONE' ? 0.6 : 1,
                  backgroundColor: isFolder ? 'transparent' : nodeColors[color],
                  minWidth: isProject ? 120 : 'undefined',
                  outline: isFocused ? '3px solid var(--blue_strong)' : '',
                  width:
                    isProject && maxProjectWidth
                      ? maxProjectWidth[1] +
                        70 +
                        (treeMultiSelect || treeMultiSelectByKey ? 15 : 0)
                      : 'undefined',
                }}
                onClick={inputWrapperClickHandler}
                onContextMenu={contextMenuHandler}
              >
                <span
                  id="hide"
                  ref={span}
                  style={{ maxWidth: isProject ? 185 : 'undefined' }}
                  className={`nodeInput ${fontSize}`}
                >
                  {nameTracker || 'Add Text'}
                </span>

                {!isTemplate && (treeMultiSelectByKey || treeMultiSelect) && (
                  <div
                    className="tree-node-multi-select"
                    onClick={() => updateSelectedItems(node)}
                  >
                    {SvgSelector(
                      treeMultiSelectList.includes(node)
                        ? 'checkBoxFill'
                        : 'checkBoxHover',
                    )}
                  </div>
                )}
                <div className="flex-row tree-node-name">
                  <div
                    className="tree-node-show-emoji"
                    style={{
                      minWidth: emoji ? Number(itemSize) : 0,
                    }}
                  >
                    {emoji && (
                      <Emoji
                        emojiUrl={
                          boardCustomEmoji
                            ? CustomBoardEmoji
                            : projectCustomEmoji
                              ? CustomProjectEmoji
                              : null
                        }
                        unified={
                          boardCustomEmoji || projectCustomEmoji
                            ? '1f4d8'
                            : emoji
                        }
                        size={itemSize}
                      />
                    )}
                  </div>
                  <div className="nodes tree-node-show-task">
                    <div
                      ref={inputRef}
                      className={`nodeInput nodes disable-text-selection tree-node-title ${
                        isFolder ? 'tree-node-title-folder' : ''
                      } ${fontSize}`}
                      autoComplete="off"
                      spellCheck="off"
                      style={{
                        textAlign: name?.length > 0 ? 'left' : 'center',
                        // marginLeft: !emoji ? -27 : 0,
                        whiteSpace: isProject ? 'normal' : 'pre',
                        wordBreak: isProject ? 'break-all' : 'normal',
                        cursor:
                          treeMultiSelect || ctrlKeyPressed
                            ? 'var(--pointer)'
                            : 'text',
                        textOverflow: isProject ? 'auto' : 'ellipsis',
                      }}
                      suppressContentEditableWarning
                      data-placeholder="Add Text"
                      onBlur={blurHandler}
                      onFocus={() => {
                        setCaret(
                          inputRef.current,
                          inputRef.current?.textContent?.length,
                        );
                        setRefresh((old) => old + 1);
                        selectNodeHandler(node, treeData);
                        // inputWrapperClickHandler()
                      }}
                      // onBlur={() => !inviteModalShown && nameChangeHandler()}
                      onKeyDown={onKeyDown}
                      onMouseDown={(e) => {
                        setShowStatusMenu(false);
                        e.stopPropagation();
                      }}
                      onInput={(e) => {
                        editableOnChange(e);
                      }}
                      contentEditable="false"
                    >
                      {name}
                    </div>
                  </div>
                </div>

                {hasNote && (
                  <div
                    className="tree-node-has-note"
                    // onMouseOver={() => inputFocused !== node && showNote(node)}
                  >
                    {SvgSelector(
                      'note',
                      !isFolder
                        ? `BoardNoteIcon-${nodeLevel}`
                        : `BoardNoteIconForFolder-${nodeLevel}`,
                    )}
                  </div>
                )}
                {commentsCounts > 0 && (
                  <div
                    className="tree-node-has-note"
                    onClick={(e) => {
                      e.stopPropagation();
                      selectNode(node);
                      setBlockParent(node);
                      dispatch({ type: 'showComment', payload: true });
                    }}
                  >
                    <div
                      className={`budget-comments budget-comments-${nodeLevel}`}
                    >
                      {commentsCounts > 9 ? '+9' : commentsCounts}
                    </div>
                    {SvgSelector('comment', `commentIcon-${nodeLevel}`)}
                  </div>
                )}
                {shownAssignmentIcon &&
                  !isFolder &&
                  treeData[node].assignments?.length > 0 && (
                    <div
                      className="flex-row tree-node-assignment"
                      onClick={() => {
                        if (userAccess === 'WRITE' && user)
                          showAssignmentMenu(node);
                      }}
                    >
                      {/* <div className="avatarAssignmentDivider" /> */}
                      <Avatar
                        size={Number(itemSize)}
                        name={treeData[node].assignments[0].email}
                        font={fontSize}
                      />
                      {treeData[node].assignments?.length > 1 && (
                        <div className={`assignment-other-users-${nodeLevel}`}>
                          +{(treeData[node].assignments?.length || 1) - 1}
                        </div>
                      )}
                    </div>
                  )}
                {schedule && (
                  <Deadline date={schedule} fontSchedule={fontSchedule} />
                )}
              </div>
            </DraggableNode>
            {userAccess === 'WRITE' &&
              inputFocused === node &&
              !togoMode &&
              (!collapse || !treeData[node]?.order?.length > 0) && (
                <div
                  ref={plusBtnRef}
                  style={{ opacity: togoMode || filterNode() ? 1 : 0.2 }}
                  onClick={addChild}
                  className="plus-btn"
                >
                  {SvgSelector('plus', 'plus-white')}
                </div>
              )}
            {order?.length > 0 && isProject && !togoMode && !collapse && (
              <div className="tree-node-point-project" />
            )}

            {inputFocused !== node && !collapse && order?.length > 0 && (
              <div
                className="cursor-pointer tree-node-spreader"
                onClick={() => setExpand(true)}
              />
            )}
          </div>
          {!togoMode &&
            inputFocused !== node &&
            collapse &&
            nestedChildren !== '0' && (
              <div
                className="plus-btn font-14-med tree-node-collapse-icon"
                style={{
                  opacity: togoMode || filterNode() ? 1 : 0.2,
                }}
                onClick={() => setExpand(false)}
              >
                {nestedChildren}
              </div>
            )}
        </div>

        <ul style={{ paddingLeft: togoMode ? 0 : 40 }}>
          {order?.length > 0 && (togoMode || !collapse) && (
            <Tree
              nodes={order}
              itemEls={itemEls}
              setRefreshUI={setRefreshUI}
              inputFocused={inputFocused}
              selectNode={selectNode}
              setInputFocus={setInputFocus}
              MainNode={MainNode}
              showNotify={showNotify}
              unDelete={unDelete}
              deleteNode={deleteNode}
              shareLayer={shareLayer}
              copyLink={copyLink}
              treeRef={treeRef}
            />
          )}
        </ul>
      </div>
    );
  return null;
}

function Tree({
  hasProject,
  nodes,
  itemEls,
  setRefreshUI,
  refreshUI,
  inputFocused,
  selectNode,
  setInputFocus,
  MainNode,
  showNotify,
  unDelete,
  deleteNode,
  shareLayer,
  isFocusMode,
  scrollRef,
  copyLink,
  treeRef,
}) {
  const { setStartNode, setFocusModeKey } = useContext(DataContext);
  return (
    <ul className="App nodes tree-container">
      {nodes?.map((item) => (
        <div className="nodes tree-parent" key={item} data-key={item}>
          {isFocusMode && (
            <div
              onClick={() => setStartNode(MainNode)}
              className="focus-mode-back"
              onMouseEnter={() => {
                setFocusModeKey(MainNode);
              }}
            >
              {SvgSelector('arrowLeft', 'focus-mode-back-svg')}
            </div>
          )}
          <TreeNode
            node={item}
            itemEls={itemEls}
            setRefreshUI={setRefreshUI}
            refreshUI={refreshUI}
            inputFocused={inputFocused}
            selectNode={selectNode}
            setInputFocus={setInputFocus}
            MainNode={MainNode}
            hasProject={hasProject}
            showNotify={showNotify}
            unDelete={unDelete}
            deleteNode={deleteNode}
            shareLayer={shareLayer}
            scrollRef={scrollRef}
            copyLink={copyLink}
            treeRef={treeRef}
          />
        </div>
      ))}
    </ul>
  );
}

export default Tree;
