import { useContext, useEffect, useRef, useState } from 'react';
import calendar, {
  isDate,
  isSameDay,
  isSameMonth,
  getDateISO,
  getNextMonth,
  getPreviousMonth,
  WEEK_DAYS,
  CALENDAR_MONTHS,
  zeroPad,
} from '../../helper/calendar';
import { SvgSelector } from '../../helper/svg-selector';
import { reminderItems } from '../../constants/arrays';
import { DataContext } from '../../context/DataContext';
import AnimationSlide from '../Elements/AnimationSlide';
import SwitchButton from './SwitchButton';
import dataServices from '../../services/dataServices';

let datePicker = {};
export default function Calendar({ node, directReq, callback, type = 'save' }) {
  const { addToQueue, treeData } = useContext(DataContext);
  const nodeId = node?.id || node;
  const nodeData = treeData[node] || node;
  const nodeStartDate = nodeData?.start_time;
  const nodeDueTime = nodeData?.due_time;
  const nodeStartTime = nodeData?.start_time;
  const nodeReminder = nodeData?.reminder;
  const nodeHasTime = nodeData?.due_time_has_time;
  const dueHourRef = useRef(null);
  const dueMinRef = useRef(null);
  const dueMiddayRef = useRef(null);
  const startHourRef = useRef(null);
  const startMinRef = useRef(null);
  const startMiddayRef = useRef(null);
  const [dueDateIncluded, setDueDateIncluded] = useState(!!nodeDueTime);
  const [isDateRange, setIsDateRange] = useState(!!nodeStartTime);
  const [includedTime, setIncludedTime] = useState(nodeHasTime || false);
  const [reminderInclude, setReminderInclude] = useState(!!nodeReminder);
  const [reminderState, setReminderState] = useState(
    nodeReminder >= 0
      ? reminderItems.filter((i) => i.time === nodeReminder)[0]
      : reminderItems[0],
  );
  const [reminderSelectorOpen, setReminderSelectorOpen] = useState(false);
  const [dueTime, setDueTime] = useState({
    hour: nodeDueTime
      ? zeroPad(new Date(nodeDueTime).getHours() % 12, 2)
      : zeroPad(new Date().getHours() % 12, 2),
    minute: nodeDueTime
      ? zeroPad(new Date(nodeDueTime).getMinutes(), 2)
      : zeroPad(new Date().getMinutes(), 2),
    midday: nodeDueTime
      ? new Date(nodeDueTime).getHours() >= 12
        ? 'PM'
        : 'AM'
      : new Date().getHours() >= 12
        ? 'PM'
        : 'AM',
  });
  const [startTime, setStartTime] = useState({
    hour:
      nodeStartTime === null && includedTime
        ? zeroPad(new Date(nodeDueTime).getHours() % 12, 2)
        : nodeStartTime
          ? zeroPad(new Date(nodeStartTime).getHours() % 12, 2)
          : zeroPad(new Date().getHours() % 12, 2),
    minute:
      nodeStartTime === null && includedTime
        ? zeroPad(new Date(nodeDueTime).getMinutes(), 2)
        : nodeStartDate
          ? zeroPad(new Date(nodeStartTime).getMinutes(), 2)
          : zeroPad(new Date().getMinutes(), 2),
    midday:
      nodeStartTime === null && includedTime
        ? new Date(nodeDueTime).getHours() >= 12
          ? 'PM'
          : 'AM'
        : nodeStartDate
          ? new Date(nodeStartTime).getHours() >= 12
            ? 'PM'
            : 'AM'
          : new Date().getHours() >= 12
            ? 'PM'
            : 'AM',
  });
  const [startDate, setStartDate] = useState(
    nodeStartDate
      ? {
          current: nodeStartTime ? new Date(nodeStartTime) : new Date(),
          month: nodeStartTime
            ? new Date(nodeStartTime).getMonth() + 1
            : new Date().getMonth() + 1,
          year: nodeStartTime
            ? new Date(nodeStartTime).getFullYear()
            : new Date().getFullYear(),
        }
      : null,
  );

  const [dueDate, setDueDate] = useState({
    current: nodeDueTime ? new Date(nodeDueTime) : null,
    month: nodeDueTime ? new Date(nodeDueTime).getMonth() + 1 : null,
    year: nodeDueTime ? new Date(nodeDueTime).getFullYear() : null,
  });

  const [openedMonthMenu, setOpenedMonthMenu] = useState(false);

  const today = new Date();

  const saveDatePickerData = (name, data) => {
    datePicker[name] = data;
  };
  const saveData = () => {
    const checkDatePicker = Object.keys(datePicker).length === 0;
    const firstDueTime = {
      hour: zeroPad(new Date().getHours() % 12, 2),
      minute: zeroPad(new Date().getMinutes(), 2),
      midday: new Date().getHours() >= 12 ? 'PM' : 'AM',
    };
    const firstStartTime = {
      hour: zeroPad(new Date().getHours() % 12, 2),
      minute: zeroPad(new Date().getMinutes(), 2),
      midday: new Date().getHours() >= 12 ? 'PM' : 'AM',
    };
    if (!checkDatePicker) {
      const d = getDateISO(datePicker?.dueDate?.current || dueDate);
      const sd = getDateISO(datePicker?.startDate?.current || startDate);
      let hour;
      let sHour;
      let t;
      let sT;
      if (datePicker.dueTime) {
        hour =
          Number(datePicker?.dueTime.hour) +
          (datePicker?.dueTime.midday === 'PM' ? 12 : 0);
        t = `${d}T${zeroPad(hour, 2)}:${datePicker?.dueTime.minute}:00`;
      } else {
        hour =
          Number(firstDueTime.hour) + (firstDueTime.midday === 'PM' ? 12 : 0);
        t = `${d}T${zeroPad(hour, 2)}:${firstDueTime.minute}:00`;
      }
      if (datePicker.startTime) {
        if (datePicker.hasTime && !datePicker.startDate) {
          sHour =
            Number(datePicker?.startTime.hour) +
            (datePicker?.startTime.midday === 'PM' ? 12 : 0);
          sT = `${d}T${zeroPad(sHour, 2)}:${datePicker?.startTime.minute}:00`;
        } else {
          sHour =
            Number(datePicker?.startTime.hour) +
            (datePicker?.startTime.midday === 'PM' ? 12 : 0);
          sT = `${sd}T${zeroPad(sHour, 2)}:${datePicker?.startTime.minute}:00`;
        }
      } else {
        sHour =
          Number(firstStartTime.hour) +
          (firstStartTime.midday === 'PM' ? 12 : 0);
        sT = `${sd}T${zeroPad(hour, 2)}:${firstStartTime.minute}:00`;
      }
      const tStartDate = datePicker.startDate?.current
        ? new Date(sT).toISOString()
        : null;
      const tDueDate =
        datePicker.hasTime && !datePicker.startDate
          ? new Date(sT).toISOString()
          : datePicker?.dueDate?.current
            ? new Date(t).toISOString()
            : null;
      const tReminder = datePicker?.reminderState?.time;
      if (callback)
        callback(tStartDate, tDueDate, tReminder, datePicker.hasTime);
      if (directReq) {
        dataServices.updateLayers(nodeId, {
          start_time: tStartDate,
          due_time: tDueDate,
          due_time_has_time: datePicker.hasTime,
          reminder: tReminder,
        });
      } else if (type !== 'filter' && type !== 'multiSelect') {
        treeData[nodeId].start_time = tStartDate;
        treeData[nodeId].due_time = tDueDate;
        treeData[nodeId].reminder = tReminder;
        treeData[nodeId].due_time_has_time = datePicker.hasTime;
        addToQueue('update', {
          id: nodeId,
          start_time: tStartDate,
          due_time: tDueDate,
          due_time_has_time: datePicker.hasTime,
          reminder: tReminder,
        });
      }
    }
    datePicker = {};
  };
  const addDateToState = (addDate) => {
    const isDateObject = isDate(addDate);
    setDueDateIncluded(true);
    const d = isDateObject ? new Date(addDate) : new Date();
    if (isDateRange) {
      if (
        new Date(addDate) < new Date(startDate?.current || dueDate?.current)
      ) {
        setStartDate({
          current: isDateObject ? addDate : null,
          month: +d.getMonth() + 1,
          year: d.getFullYear(),
        });
        saveDatePickerData('startDate', {
          current: isDateObject ? addDate : null,
          month: +d.getMonth() + 1,
          year: d.getFullYear(),
        });
      }
      if (
        new Date(addDate) > new Date(startDate?.current || dueDate?.current)
      ) {
        if (!startDate?.current) {
          setStartDate({
            current: isDateObject ? dueDate?.current : null,
            month: +d.getMonth() + 1,
            year: d.getFullYear(),
          });
          saveDatePickerData('startDate', {
            current: isDateObject ? dueDate?.current : null,
            month: +d.getMonth() + 1,
            year: d.getFullYear(),
          });
        }
        setDueDate({
          current: isDateObject ? addDate : null,
          month: +d.getMonth() + 1,
          year: d.getFullYear(),
        });
        saveDatePickerData('dueDate', {
          current: isDateObject ? addDate : null,
          month: +d.getMonth() + 1,
          year: d.getFullYear(),
        });
      }
    } else {
      setDueDate({
        current: isDateObject ? addDate : null,
        month: +d.getMonth() + 1,
        year: d.getFullYear(),
      });
      saveDatePickerData('dueDate', {
        current: isDateObject ? addDate : null,
        month: +d.getMonth() + 1,
        year: d.getFullYear(),
      });
    }
  };

  const getCalendarDates = () => {
    const calendarMonth = new Date().getMonth() + 1;
    const calendarYear = new Date().getFullYear();
    return calendar(calendarMonth, calendarYear);
  };

  const renderMonthAndYear = () => {
    let month;
    let year;
    if (dueDate.current) {
      month = dueDate.month;
      year = dueDate.year;
    } else {
      month = new Date().getMonth() + 1;
      year = new Date().getFullYear();
    }
    const monthName =
      Object.keys(CALENDAR_MONTHS)[Math.max(0, Math.min(month - 1, 11))];
    return (
      <div className="calendar-header">
        <div
          className="calendar-header-arrows flip hover"
          onClick={gotoPreviousMonth}
        >
          {SvgSelector('chevronRight')}
        </div>

        <div
          className="font-16-med cursor-pointer calendar-more-month-container"
          onClick={() => setOpenedMonthMenu(!openedMonthMenu)}
        >
          <div>
            {monthName} {year}
          </div>
          {SvgSelector(
            openedMonthMenu ? 'chevronUp' : 'chevronDown',
            'calendar-more-month-icon',
          )}
        </div>
        <AnimationSlide
          openMenu={setOpenedMonthMenu}
          show={openedMonthMenu}
          state="topToDown"
          menuClassName="calendar-header-more-mounth-menu box-shadow"
        >
          {Object.keys(CALENDAR_MONTHS).map((i, index) => {
            const numberMonth = index + 1;
            if (numberMonth >= month) {
              return (
                <div
                  key={i.id}
                  className="hover calendar-header-more-item font-14-med cursor-pointer"
                  onClick={() => goToselectedMonth(numberMonth)}
                >
                  <div>
                    {i} {year}
                  </div>
                  {monthName === i && SvgSelector('checkMark')}
                </div>
              );
            }
            return null;
          })}
          {Object.keys(CALENDAR_MONTHS).map((i, index) => {
            const numberMonth = index + 1;
            if (numberMonth < month) {
              return (
                <div
                  key={i.id}
                  className="hover calendar-header-more-item font-14-med cursor-pointer"
                  onClick={() => goToselectedMonth(numberMonth)}
                >
                  <div>
                    {i} {year}
                  </div>
                  {monthName === i && SvgSelector('checkMark')}
                </div>
              );
            }
            return null;
          })}
        </AnimationSlide>
        <div className="calendar-header-arrows hover" onClick={gotoNextMonth}>
          {SvgSelector('chevronRight')}
        </div>
      </div>
    );
  };

  const renderDayLabel = (day) => {
    const dayLabel = WEEK_DAYS[day].toUpperCase();

    return (
      <div className="center font-14-med calendar-days" key={dayLabel}>
        {dayLabel}
      </div>
    );
  };

  const renderCalendarDate = (d) => {
    let month;
    let year;
    if (dueDate.current) {
      month = dueDate.month;
      year = dueDate.year;
    } else {
      month = new Date().getMonth() + 1;
      year = new Date().getFullYear();
    }
    const thisDate = new Date(d);
    const tDate = new Date(d.join('-'));
    const isToday = isSameDay(tDate, today);
    const isCurrentDue =
      (dueDate || datePicker.dueDate) &&
      isSameDay(
        new Date(thisDate.getTime()),
        new Date(dueDate.current?.getTime()),
      );
    const isCurrentStart =
      isDateRange &&
      startDate &&
      isSameDay(
        new Date(thisDate.getTime()),
        new Date(startDate?.current?.getTime()),
      );
    const isCurrentSelected =
      isDateRange &&
      dueDateIncluded &&
      dueDate.current &&
      startDate?.current &&
      startDate?.current?.getTime() < thisDate.getTime() &&
      thisDate.getTime() < dueDate.current?.getTime();
    const inMonth =
      month && year && isSameMonth(tDate, new Date([year, month, 1].join('-')));
    let className =
      isCurrentDue || isCurrentStart
        ? 'highlighted-calendarDate'
        : isCurrentSelected
          ? 'highlighted-calendarDate-range'
          : inMonth
            ? 'calendar-date-same-month'
            : 'calendar-date';
    className += ' calendar-day font-14-med hover';
    if (isToday) className += ' calendar-day-today';
    return (
      <div
        className={className}
        key={getDateISO(tDate)}
        onClick={() => addDateToState(thisDate)}
      >
        {tDate.getDate()}
      </div>
    );
  };

  const gotoPreviousMonth = () => {
    const { month, year } = dueDate;
    const previousMonth = getPreviousMonth(month, year);
    setDueDate({
      month: previousMonth.month,
      year: previousMonth.year,
      current: dueDate.current,
    });
    saveDatePickerData('dueDate', {
      month: previousMonth.month,
      year: previousMonth.year,
      current: dueDate.current,
    });
    setDueDate({
      month: previousMonth.month,
      year: previousMonth.year,
      current: dueDate.current,
    });
  };
  const gotoNextMonth = () => {
    const { month, year } = dueDate;
    const nextMonth = getNextMonth(month, year);
    setDueDate({
      month: nextMonth.month,
      year: nextMonth.year,
      current: dueDate.current,
    });
    saveDatePickerData('dueDate', {
      month: nextMonth.month,
      year: nextMonth.year,
      current: dueDate.current,
    });
    setDueDate({
      month: nextMonth.month,
      year: nextMonth.year,
      current: dueDate.current,
    });
  };

  const goToselectedMonth = (monthDate) => {
    setDueDate({
      month: monthDate,
      year: dueDate.year,
      current: dueDate.current,
    });
    saveDatePickerData('dueDate', {
      month: monthDate,
      year: dueDate.year,
      current: dueDate.current,
    });
    setDueDate({
      month: monthDate,
      year: dueDate.year,
      current: dueDate.current,
    });
    setOpenedMonthMenu(false);
  };

  const clearData = () => {
    setDueDateIncluded(null);
    let tDueDate = dueDate;
    tDueDate = { current: null, month: null, year: null };
    setDueDate(tDueDate);
    delete datePicker.dueDate;
    delete datePicker.startDate;
    setStartDate(tDueDate);
    setIncludedTime(false);
    setIsDateRange(false);
    setReminderInclude(false);
  };

  const clearDataWithKeys = (event) => {
    if ((event.key === 'd' && event.ctrlKey) || event.key === 'Delete') {
      clearData();
    }
  };

  useEffect(() => {
    datePicker.startDate = startDate;
  }, [startDate]);
  useEffect(() => {
    datePicker.dueDate = dueDate;
  }, [dueDate]);
  useEffect(() => {
    if (!reminderInclude) [datePicker.reminderState] = reminderItems;
  }, [reminderInclude]);
  useEffect(() => {
    datePicker.hasTime = includedTime;
  }, [includedTime]);
  useEffect(() => {
    datePicker.startTime = startTime;
  }, [startTime]);
  useEffect(() => {
    datePicker.dueTime = dueTime;
  }, [dueTime]);
  useEffect(() => {
    if (!isDateRange) {
      setStartDate(null);
    }
  }, [isDateRange]);
  useEffect(() => {
    datePicker.reminderState =
      nodeReminder >= 0
        ? reminderItems.filter((i) => i.time === nodeReminder)[0]
        : reminderItems[0];
    return () => {
      saveData();
    };
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', clearDataWithKeys);
    return () => {
      document.removeEventListener('keydown', clearDataWithKeys);
    };
  }, []);

  const reminderSelectorHandler = (item) => {
    setReminderState(item);
    saveDatePickerData('reminderState', item);

    setReminderSelectorOpen(false);
  };

  const changeStartTimeHandler = (val, item) => {
    if (item === 'midday') {
      const value = val.toUpperCase();
      const regex = /^$|^[A|P]{1}M*$/;
      if (regex.test(value))
        setStartTime((prevState) => ({ ...prevState, [item]: value }));
      saveDatePickerData('startTime', { ...startTime, [item]: value });
    } else {
      const regex = /^$|^[0-9\b]+$/;
      if (!regex.test(val)) return;
      setStartTime((prevState) => ({ ...prevState, [item]: val }));
      saveDatePickerData('startTime', { ...startTime, [item]: val });
      if (val.length > 1) {
        if (item === 'hour') {
          startMinRef?.current.select();
          startMinRef?.current.focus();
        }
        if (item === 'minute') {
          startMiddayRef?.current.select();
          startMiddayRef?.current.focus();
        }
      }
    }
  };

  const onBlurHandlerStart = (value, item) => {
    if (item === 'hour') {
      if (value > 23 || value < 1) {
        changeStartTimeHandler(12, 'hour');
      } else if (value > 12) {
        changeStartTimeHandler(zeroPad(value % 12, 2), 'hour');
        changeStartTimeHandler('PM', 'midday');
      } else {
        changeStartTimeHandler(zeroPad(value, 2), 'hour');
      }
    } else if (item === 'minute') {
      changeStartTimeHandler(zeroPad(value > 60 ? 0 : value, 2), 'minute');
    } else if (item === 'midday' && value.length < 2) {
      if (value === 'P') changeStartTimeHandler('PM', 'midday');
      else changeStartTimeHandler('AM', 'midday');
    }
  };

  const clearStartTime = () => {
    onBlurHandlerStart('', 'hour');
    onBlurHandlerStart('', 'minute');
    onBlurHandlerStart('', 'midday');
  };

  const changeDueTimeHandler = (val, item) => {
    if (item === 'midday') {
      const value = val.toUpperCase();
      const regex = /^$|^[A|P]{1}M*$/;
      if (regex.test(value))
        setDueTime((prevState) => ({ ...prevState, [item]: value }));
      saveDatePickerData('dueTime', { ...dueTime, [item]: value });
    } else {
      const regex = /^$|^[0-9\b]+$/;
      if (!regex.test(val)) return;
      setDueTime((prevState) => ({ ...prevState, [item]: val }));
      saveDatePickerData('dueTime', { ...dueTime, [item]: val });
      if (val.length > 1) {
        if (item === 'hour') {
          dueMinRef?.current.select();
          dueMinRef?.current.focus();
        }
        if (item === 'minute') {
          dueMiddayRef?.current.select();
          dueMiddayRef?.current.focus();
        }
      }
    }
  };

  const onBlurHandler = (value, item) => {
    if (item === 'hour') {
      if (value > 23 || value < 1) {
        changeDueTimeHandler(12, 'hour');
      } else if (value > 12) {
        changeDueTimeHandler(zeroPad(value % 12, 2), 'hour');
        changeDueTimeHandler('PM', 'midday');
      } else {
        changeDueTimeHandler(zeroPad(value, 2), 'hour');
      }
    } else if (item === 'minute') {
      changeDueTimeHandler(zeroPad(value > 60 ? 0 : value, 2), 'minute');
    } else if (item === 'midday' && value.length < 2) {
      if (value === 'P') changeDueTimeHandler('PM', 'midday');
      else changeDueTimeHandler('AM', 'midday');
    }
  };

  const clearDueTime = () => {
    onBlurHandler('', 'hour');
    onBlurHandler('', 'minute');
    onBlurHandler('', 'midday');
  };

  const onDueTimeSelect = () => {
    dueHourRef?.current?.select();
    dueHourRef?.current?.focus();
    startHourRef?.current?.select();
    startHourRef?.current?.focus();
  };

  return (
    <div>
      <div className="calendar-container box-shadow">
        {renderMonthAndYear()}
        <div className="calendar-grid">
          {Object.keys(WEEK_DAYS).map(renderDayLabel)}
          {getCalendarDates().map(renderCalendarDate)}
        </div>
        {/* start date */}
        <div className="calendar-tail">
          <div className="flex-row">
            {isDateRange ? (
              <div className="calendar-dueDate-time-container">
                <div className="calendar-dueDate-input-container">
                  <div className="calendar-input-dueDate-wrapper calendar-hover-inputs">
                    <input
                      className="calendar-input-start-date font-14-reg"
                      readOnly
                      placeholder="Start date"
                      value={
                        dueDate?.current
                          ? getDateISO(startDate?.current)?.split('-').join('/')
                          : ''
                      }
                    />
                    <div
                      onClick={() => {
                        setStartDate({
                          current: null,
                          month: null,
                          year: null,
                        });
                        saveDatePickerData('startDate', {
                          current: null,
                          month: null,
                          year: null,
                        });
                        setIsDateRange(false);
                        saveDatePickerData('dueDateIncluded', false);
                      }}
                    >
                      {SvgSelector('close', 'deleteValue-icon cursor-pointer')}
                    </div>
                  </div>
                  <span className="calendar-separator-inputs">-</span>
                  <div className="calendar-input-dueDate-wrapper calendar-hover-inputs">
                    <input
                      className="calendar-input-start-date font-14-reg"
                      readOnly
                      placeholder="End date"
                      value={
                        dueDate.current
                          ? getDateISO(dueDate.current).split('-').join('/')
                          : ''
                      }
                    />
                    <div
                      onClick={() => {
                        setDueDate({
                          ...dueDate,
                          current: new Date(),
                        });
                        saveDatePickerData('dueDate', {
                          ...dueDate,
                          current: new Date(),
                        });
                        setDueDate({
                          month: null,
                          year: null,
                          current: null,
                        });
                        setIsDateRange(false);
                        saveDatePickerData('dueDateIncluded', false);
                      }}
                    >
                      {SvgSelector('close', 'deleteValue-icon cursor-pointer')}
                    </div>
                  </div>
                </div>
                {includedTime && (
                  <div className="calendar-dueDate-input-container">
                    <div
                      className="calendar-input-dueDate-wrapper calendar-hover-inputs"
                      onClick={onDueTimeSelect}
                    >
                      <div className="calendar-included-time-container">
                        <input
                          ref={startHourRef}
                          className="calendar-input-time font-14-reg"
                          value={startTime.hour}
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) =>
                            changeStartTimeHandler(e.target.value, 'hour')
                          }
                          maxLength={2}
                          onBlur={(e) =>
                            onBlurHandlerStart(e.target.value, 'hour')
                          }
                        />
                        <div className="calendar-input-time-semi">:</div>
                        <input
                          ref={startMinRef}
                          className="calendar-input-time font-14-reg"
                          value={startTime.minute}
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) =>
                            changeStartTimeHandler(e.target.value, 'minute')
                          }
                          onBlur={(e) =>
                            onBlurHandlerStart(e.target.value, 'minute')
                          }
                          maxLength={2}
                        />
                        <input
                          ref={startMiddayRef}
                          className="calendar-input-time mh-5 w-25 font-14-reg"
                          onClick={(e) => e.stopPropagation()}
                          value={startTime.midday}
                          onChange={(e) =>
                            changeStartTimeHandler(e.target.value, 'midday')
                          }
                          onBlur={(e) => {
                            onBlurHandlerStart(e.target.value, 'midday');
                          }}
                          maxLength={2}
                        />
                      </div>
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          clearStartTime();
                        }}
                      >
                        {SvgSelector(
                          'close',
                          'deleteValue-icon cursor-pointer',
                        )}
                      </div>
                    </div>
                    <span className="calendar-separator-inputs">-</span>
                    <div
                      className="calendar-input-dueDate-wrapper calendar-hover-inputs"
                      onClick={onDueTimeSelect}
                    >
                      <div className="calendar-included-time-container">
                        <input
                          ref={dueHourRef}
                          className="calendar-input-time font-14-reg"
                          value={dueTime.hour}
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) =>
                            changeDueTimeHandler(e.target.value, 'hour')
                          }
                          maxLength={2}
                          onBlur={(e) => onBlurHandler(e.target.value, 'hour')}
                        />
                        <div className="calendar-input-time-semi">:</div>
                        <input
                          ref={dueMinRef}
                          className="calendar-input-time font-14-reg"
                          value={dueTime.minute}
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) =>
                            changeDueTimeHandler(e.target.value, 'minute')
                          }
                          onBlur={(e) =>
                            onBlurHandler(e.target.value, 'minute')
                          }
                          maxLength={2}
                        />
                        <input
                          ref={dueMiddayRef}
                          className="calendar-input-time mh-5 w-25 font-14-reg"
                          onClick={(e) => e.stopPropagation()}
                          value={dueTime.midday}
                          onChange={(e) =>
                            changeDueTimeHandler(e.target.value, 'midday')
                          }
                          onBlur={(e) =>
                            onBlurHandler(e.target.value, 'midday')
                          }
                          maxLength={2}
                        />
                      </div>
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          clearDueTime();
                        }}
                      >
                        {SvgSelector(
                          'close',
                          'deleteValue-icon cursor-pointer',
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : includedTime ? (
              <div className="calendar-dueDate-time-container">
                <div className="calendar-dueDate-input-container">
                  <div className="calendar-input-dueDate-wrapper calendar-hover-inputs">
                    <input
                      className="calendar-input-start-date font-14-reg"
                      readOnly
                      placeholder="Start date"
                      value={
                        nodeDueTime || datePicker.dueDate
                          ? getDateISO(dueDate?.current)?.split('-').join('/')
                          : ''
                      }
                    />
                    <div
                      onClick={() => {
                        setStartDate({
                          current: new Date(),
                          month: new Date().getMonth() + 1,
                          year: new Date().getFullYear(),
                        });
                        saveDatePickerData('startDate', {
                          current: new Date(),
                          month: new Date().getMonth() + 1,
                          year: new Date().getFullYear(),
                        });
                        setIsDateRange(false);
                        saveDatePickerData('dueDateIncluded', false);
                      }}
                    >
                      {SvgSelector('close', 'deleteValue-icon cursor-pointer')}
                    </div>
                  </div>
                  <span className="calendar-separator-inputs">-</span>
                  <div
                    className="calendar-input-dueDate-wrapper calendar-hover-inputs"
                    onClick={onDueTimeSelect}
                  >
                    <div className="calendar-included-time-container">
                      <input
                        ref={startHourRef}
                        className="calendar-input-time font-14-reg"
                        value={startTime.hour}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) =>
                          changeStartTimeHandler(e.target.value, 'hour')
                        }
                        maxLength={2}
                        onBlur={(e) =>
                          onBlurHandlerStart(e.target.value, 'hour')
                        }
                      />
                      <div className="calendar-input-time-semi">:</div>
                      <input
                        ref={startMinRef}
                        className="calendar-input-time font-14-reg"
                        value={startTime.minute}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) =>
                          changeStartTimeHandler(e.target.value, 'minute')
                        }
                        onBlur={(e) =>
                          onBlurHandlerStart(e.target.value, 'minute')
                        }
                        maxLength={2}
                      />
                      <input
                        ref={startMiddayRef}
                        className="calendar-input-time mh-5 w-25 font-14-reg"
                        onClick={(e) => e.stopPropagation()}
                        value={startTime.midday}
                        onChange={(e) =>
                          changeStartTimeHandler(e.target.value, 'midday')
                        }
                        onBlur={(e) => {
                          onBlurHandlerStart(e.target.value, 'midday');
                        }}
                        maxLength={2}
                      />
                    </div>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        clearStartTime();
                      }}
                    >
                      {SvgSelector('close', 'deleteValue-icon cursor-pointer')}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="calendar-input-start-date-container calendar-hover-inputs">
                <input
                  className="calendar-input-start-date font-14-reg"
                  readOnly
                  placeholder="Select date"
                  value={
                    nodeDueTime || datePicker.dueDate
                      ? getDateISO(dueDate?.current)?.split('-').join('/')
                      : ''
                  }
                />
                <div
                  onClick={() => {
                    setStartDate({
                      current: new Date(),
                      month: new Date().getMonth() + 1,
                      year: new Date().getFullYear(),
                    });
                    saveDatePickerData('startDate', {
                      current: new Date(),
                      month: new Date().getMonth() + 1,
                      year: new Date().getFullYear(),
                    });
                  }}
                >
                  {SvgSelector('close', 'deleteValue-icon cursor-pointer')}
                </div>
              </div>
            )}
          </div>
        </div>
        {/* toggle switch button */}
        <div className="calendar-dateRange-select-container">
          <div className="calendar-dateRange-select">
            <div className="font-12-med">Date range</div>
            <SwitchButton
              setIsToggled={setIsDateRange}
              saveDatePickerData={saveDatePickerData}
              nameSaveData="dueDateIncluded"
              isToggled={isDateRange}
              name="date"
            />
          </div>
          <div className="calendar-dateRange-select">
            <div className="font-12-med">Include time</div>
            <SwitchButton
              setIsToggled={setIncludedTime}
              saveDatePickerData={saveDatePickerData}
              nameSaveData="startDateIncluded"
              isToggled={includedTime}
              name="time"
            />
          </div>
          {dueDate.current && type !== 'filter' && (
            <div className="calendar-dateRange-select">
              <div className="font-12-med">Due date reminder</div>
              <SwitchButton
                setIsToggled={setReminderInclude}
                isToggled={reminderInclude}
                saveDatePickerData={saveDatePickerData}
                nameSaveData="reminderInclude"
                name="reminder"
              />
            </div>
          )}
          {/* reminder */}
          {reminderInclude && (
            <div className="calendar-reminder-container">
              <div
                className="calendar-reminder-selector"
                onClick={() =>
                  dueDateIncluded
                    ? setReminderSelectorOpen(!reminderSelectorOpen)
                    : null
                }
              >
                <div className="font-16-med calendar-reminder-selector-text">
                  {reminderState?.name}
                </div>
                {SvgSelector('chevronDown')}
              </div>
            </div>
          )}
          {reminderSelectorOpen && (
            <div className="calendar-reminder-selector-items box-shadow">
              {reminderItems.map((item) => (
                <div
                  key={item.id}
                  className="calendar-reminder-selector-item font-14-reg hover"
                  onClick={() => reminderSelectorHandler(item)}
                >
                  {item.name}
                </div>
              ))}
            </div>
          )}
          <div
            onClick={clearData}
            className="calendar-clear-all cursor-pointer hover"
          >
            <span className="font-14-med calendar-clear-all-color">
              Clear all
            </span>
            <div className="font-12-reg calendar-clear-all-color">
              Del or Ctrl + D
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
